import React from "react";
import { connect } from "react-redux";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { commonActions } from "../_actions";
import { Link } from "react-router-dom";
import AOS from "aos";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: "",
      },
      open: false,
      submitted: false,
    };
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // or simply just AOS.init();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
    const { web_config } = this.props;
    if (Object.entries(web_config).length === 0) {
      this.props.getconfig();
    }
  }

  render() {
    return (
      <div className="home-wrapper">
        <section className="banner-area">
          <div className="container-fluid">
            <div className="row px-0">
              <div className="col-lg-7">
                <div className="banner-content position-relative">
                  <img
                    src={require("../assets/images/art-line-device.png")}
                    className="artline"
                    alt="img"
                  />
                  <h1 className="mb-4">
                    WAZIRX IS INDIA'S MOST TRUSTED BITCOIN & CRYPTOCURRENCY
                    EXCHANGE
                  </h1>
                  <p className="mb-0">
                    Buy, Sell & Trade Bitcoin, Ethereum, Ripple, Litecoin and
                    more cryptocurrencies in India. Start trading now!
                  </p>
                  <div className="buton-grp">
                    <a href="/signup">
                      <button type="text" className="btn btn-primary">
                        Sign Up Now
                      </button>
                    </a>
                    <div className="storebnt mt-4">
                      <a title="Play Store">
                        <img
                          src={require("../assets/images/play-store.png")}
                          // src="images/play-store.png"
                        />
                      </a>
                      <a title="Play Store">
                        <img
                          src={require("../assets/images/app-store.png")}
                          // src="images/app-store.png"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 pe-0">
                <div className="banner-image">
                  <img
                    src={require("../assets/images/headerImage.png")}
                    // src="images/headerImage.png"
                    alt="img"
                    className="d-lg-block d-none"
                  />
                  <img
                    src={require("../assets/images/landing-hero-mobile.png")}
                    // src="images/landing-hero-mobile.png"
                    alt="img"
                    className="d-lg-none d-block"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="intreducing-box">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="intrudcing-img">
                  <img
                    src={require("../assets/images/wrxCoin.png")}
                    // src="images/wrxCoin.png"
                    alt="INTRODUCING WRX!"
                  />
                </div>
              </div>
              <div className="col-lg-9">
                <div className="intreducing-content ps-lg-5 position-relative">
                  <img
                    src={require("../assets/images/artline-2.png")}
                    // src="images/artline-2.png"
                    alt="img"
                    className="instrudcing-artline"
                  />
                  <h3>INTRODUCING WRX!</h3>
                  <p className="f-16 mb-4">
                    Just like Bitcoin and other cryptocurrencies today, WRX is a
                    token created by WazirX. There will be a maximum of 1
                    Billion WRX coins ever created.
                  </p>
                  <hr className="my-4" />
                  <p>
                    WRX, a utility token backed by WazirX, forms the backbone of
                    WazirX ecosystem. We launched WRX tokens to involve our
                    community in helping us build out WazirX, and reward them
                    accordingly for contributing to our success. This helps us
                    stay true to the ethos of cryptocurrency and blockchain - to
                    share the rewards of WazirX's success with our early
                    adopters and supporters.
                  </p>
                  <div className="d-md-flex text-button justify-content-between">
                    <a title="Read our whitepaper">Read our whitepaper</a>
                    <a title="View WRX market pairs">View WRX market pairs</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="featured-area text-center">
            <div className="container-fluid">
              <h4 className="border-title">FEATURED IN</h4>
              <div className="marquee-logo">
                <ul className="list-unstyled logo-list">
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo1.png")}
                        // src="images/logo1.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo2.png")}
                        // src="images/logo2.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo3.png")}
                        // src="images/logo3.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo4.png")}
                        // src="images/logo4.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo5.png")}
                        // src="images/logo5.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo6.png")}
                        // src="images/logo6.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo7.png")}
                        // src="images/logo7.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo8.png")}
                        // src="images/logo8.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo9.png")}
                        // src="images/logo9.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo10.png")}
                        // src="images/logo10.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo4.png")}
                        // src="images/logo4.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo5.png")}
                        // src="images/logo5.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo6.png")}
                        // src="images/logo6.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo7.png")}
                        // src="images/logo7.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo8.png")}
                        // src="images/logo8.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo9.png")}
                        // src="images/logo9.png"
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img
                        src={require("../assets/images/logo10.png")}
                        // src="images/logo10.png"
                        alt="img"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="downloadapp mt-4">
          <div className="donloadside-img">
            <img
              src={require("../assets/images/headerImage.png")}
              // src="images/headerImage.png"
              alt="img"
              className="w-100"
            />
          </div>
          <div className="container">
            <h2>DOWNLOAD WAZIRX</h2>
            <ul className="list-unstyled d-flex justify-content-center downloadappp-buttons">
              <li>
                <a>
                  <img
                    src={require("../assets/images/app-store-app.png")}
                    // src="images/app-store-app.png"
                  />
                </a>
              </li>
              <li>
                <a>
                  <img
                    src={require("../assets/images/window-app.png")}
                    // src="images/window-app.png"
                  />
                </a>
              </li>
              <li>
                <a>
                  <img
                    src={require("../assets/images/playstore-app.png")}
                    // src="images/playstore-app.png"
                  />
                </a>
              </li>
              <li>
                <a>
                  <img
                    src={require("../assets/images/mac-app.png")}
                    // src="images/mac-app.png"
                  />
                </a>
              </li>
            </ul>
          </div>
        </section>

        <section className="servicesa-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="services-item">
                  <img
                    src={require("../assets/images/class-security.png")}
                    // src="images/class-security.png"
                  />
                  <h5>BEST IN CLASS SECURITY</h5>
                  <p>
                    We’ve left no stone unturned to make WazirX India’s most
                    secure exchange. We’re investing in regular security audits
                    to ensure a highly secured trading platform for India.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="services-item">
                  <img
                    src={require("../assets/images/Super-Fast.png")}
                    // src="images/Super-Fast.png"
                    alt="Super Fast KYC"
                  />
                  <h5>Super Fast KYC</h5>
                  <p>
                    Top of the line identity verification systems process your
                    KYC within a few hours of signing up, while ensuring the
                    right KYC protocol. We’re building in robustness to sharply
                    reduce verification times.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="services-item">
                  <img
                    src={require("../assets/images/Lightning-transactions.png")}
                    // src="images/Lightning-transactions.png"
                    alt="Lightning Speed Transactions"
                  />
                  <h5>Lightning Speed Transactions</h5>
                  <p>
                    WazirX can handle Millions of transactions. Our system
                    infrastructure can scale up in a few seconds to match
                    surging demand.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="services-item">
                  <img
                    src={require("../assets/images/across-platfrom.png")}
                    // src="images/across-platfrom.png"
                    alt="ACROSS 5 PLATFORMS"
                  />
                  <h5>ACROSS 5 PLATFORMS</h5>
                  <p>
                    WazirX offers a seamless and powerful trading experience
                    across all platforms - on Web, Android & iOS mobile,
                    Windows, and Mac apps.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="services-item">
                  <img
                    src={require("../assets/images/Simple-Efficient-Design.png")}
                    // src="images/Simple-Efficient-Design.png"
                    alt="Simple & Efficient Design"
                  />
                  <h5>Simple & Efficient Design</h5>
                  <p>
                    Trading on the WazirX platform is a super fast experience
                    you’ll fall in love with! We’ve built technology used by
                    Millions, and that experience has helped us build this
                    powerful exchange.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="services-item">
                  <img
                    src={require("../assets/images/Blockchain-Believers.png")}
                    // src="images/Blockchain-Believers.png"
                    alt="Built By Blockchain Believers"
                  />
                  <h5>Built By Blockchain Believers</h5>
                  <p>
                    We’re a team of hardcore traders & passionate blockchain
                    believers. We understand exactly what you need. Powerful
                    graphs, mobile apps, etc are features being built into
                    WazirX from day 1.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="wazirx-everyone">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <h2>WAZIRX IS FOR EVERYONE</h2>
                <p className="f-22">
                  We’re building an exchange that will bring anyone who believes
                  in crypto to join the digital cryptocurrency revolution. The
                  world is moving on to this revolution at an unprecedented
                  pace.
                </p>
                <p>
                  Now is your time. With WazirX you can buy, sell & trade
                  crytpocurrencies with amazing ease, confidence and trust.
                  Whether you’re a first time investor or a professional trader
                  - WazirX has got you both covered!
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  const { detail: web_config } = state.config;
  return { user, web_config };
}

const actionCreators = {
  getconfig: commonActions.getConfig,
};

export default connect(mapState, actionCreators)(Index);
