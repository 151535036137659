import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

import DefaultNavbar from "../components/layout/MainNavbar/DefaultNavbar";
import MainFooter from "../components/layout/MainFooter";

import "../assets/css/style.css";
import "../assets/css/responsive.css";
import "../assets/css/color-three.css";

const DefaultLayout = ({
  children,
  noNavbar,
  noFooter,
  match,
  headerFixed,
}) => (
  <div className="main-page-wrapper">
    <div className="html-top-content">
      {!noNavbar && <DefaultNavbar match={match} headerFixed={headerFixed} />}
      {children}
      {!noFooter && <MainFooter />}
    </div>
  </div>
);

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default DefaultLayout;
