import { authHeader } from "../_helpers";
var CryptoJS = require("crypto-js");
export const userService = {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
  changePassword,
  updateKyc,
  buyCrypto,
  sellCrypto,
  getAll,
  getOne,
  getById,
  update,
  delete: _delete,
  completedOrders,
  openOrders,
  getProfile,
  openOrdersAll,
  completedOrdersAll,
  editBuyOrder,
  getCurrency,
  editCurrency,
};

function login(user_name, password) {
  let login_data = { user_name, password };

  //let encKey = "Hima Test Key";
  //let iv = CryptoJS.enc.Hex.parse("FgLFXEr1MZl2mEnk");
  //var encrypted_data = CryptoJS.AES.encrypt(JSON.stringify(login_data), encKey, { iv: iv }).toString();

  let buff = Buffer(JSON.stringify(login_data));
  let encrypted_data = buff.toString("base64");

  //let base64ToString = Buffer.from(stringToBase64, "base64").toString();
  //base64ToString = JSON.parse(base64ToString);
  //console.log('decrypted data : ',base64ToString);

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: encrypted_data,
  };

  return fetch(`${process.env.REACT_APP_API_URL}/user/login`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let user = Buffer.from(data.data, "base64").toString();
      console.log("user", user);
      user = JSON.parse(user);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.removeItem("GetStartedShow");
      return user;
    });
}

function forgotPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/user/forgotpassword`,
    requestOptions
  ).then(handleResponse);
}

function resetPassword(password, confirm_password, reset_token) {
  var postData = {
    password: password,
    confirm_password: confirm_password,
  };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(postData),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/user/veryfypassword?reset_token=` +
      reset_token,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("profile");
  localStorage.removeItem("enterprise_profile");
  localStorage.removeItem("userSelectedPlan");
  localStorage.removeItem("GetStartedShow");
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users`, requestOptions).then(
    handleResponse
  );
}

function getOne() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/profile`, requestOptions)
    .then(handleResponse)
    .then((profile) => {
      localStorage.setItem("profile", JSON.stringify(profile.data));
      localStorage.setItem(
        "enterprise_profile",
        JSON.stringify(profile.data.company)
      );
      return profile;
    });
}

function getProfile() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  console.log("requestOptions", requestOptions);
  return fetch(
    `${process.env.REACT_APP_API_URL}/user/getuser`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    requestOptions
  ).then(handleResponse);
}

function register(user) {
  var postData = {
    first_name: user.firstName,
    last_name: user.lastName,
    user_name: user.userName,
    email: user.email,
    password: user.password,
    confirm_password: user.c_password,
    referal_code: user.referal_code,
  };

  let buff = Buffer(JSON.stringify(postData));
  let encrypted_data = buff.toString("base64");

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: encrypted_data,
  };

  console.log("requestOptions register", requestOptions);

  return fetch(`${process.env.REACT_APP_API_URL}/user/signup`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // let user = Buffer.from(data.data, "base64").toString();
      let user = Buffer.from(data.data, "base64").toString();
      user = JSON.parse(user);
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    });
}

function changePassword(password, confirm_password) {
  var postData = {
    password: password,
    confirm_password: confirm_password,
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(postData),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/user/changepassword`,
    requestOptions
  ).then(handleResponse);
}

function updateKyc(postData) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: postData,
    redirect: "follow",
  };
  console.log("requestOptions KYC", requestOptions);

  return fetch(
    `${process.env.REACT_APP_API_URL}/user/userkyc`,
    requestOptions
  ).then(handleResponse);
}

function buyCrypto(postData) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(postData),
    redirect: "follow",
  };
  console.log("Buy Crypto", requestOptions);
  return fetch(
    `${process.env.REACT_APP_API_URL}/user/buy`,
    requestOptions
  ).then(handleResponse);
}

function sellCrypto(postData) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(postData),
    redirect: "follow",
  };
  console.log("Sell Crypto", requestOptions);
  return fetch(
    `${process.env.REACT_APP_API_URL}/user/sell`,
    requestOptions
  ).then(handleResponse);
}

function completedOrders(postData) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(postData),
    redirect: "follow",
  };
  console.log("Completed Order ", requestOptions);
  return fetch(
    `${process.env.REACT_APP_API_URL}/user/completedorder`,
    requestOptions
  ).then(handleResponse);
}

function openOrders(postData) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(postData),
    redirect: "follow",
  };
  console.log("open Order ", requestOptions);
  return fetch(
    `${process.env.REACT_APP_API_URL}/user/openorder`,
    requestOptions
  ).then(handleResponse);
}

function openOrdersAll() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/user/allopenorder`,
    requestOptions
  ).then(handleResponse);
}

function completedOrdersAll() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/user/allcompletedorder`,
    requestOptions
  ).then(handleResponse);
}

function getCurrency() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/user/currencypreference`,
    requestOptions
  ).then(handleResponse);
}

function editCurrency(asset_id) {
  var postData = {
    asset_id: asset_id,
  };
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(postData),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/user/changecurrencypreference`,
    requestOptions
  ).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/user/updateprofile`,
    requestOptions
  )
    .then(handleResponse)
    .then((profile) => {
      localStorage.setItem("profile", JSON.stringify(profile.data));
      return profile;
    });
}

function changeCompanyLogo(postData) {
  let formData = new FormData();
  formData.append("logo", postData.logo);

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: formData,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/onboard/company/logo`,
    requestOptions
  )
    .then(handleResponse)
    .then((profile) => {
      localStorage.setItem("profile", JSON.stringify(profile.data));
      return profile;
    });
}

function updateCompanyColor(postData) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(postData),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/brand-kit/colors`,
    requestOptions
  )
    .then(handleResponse)
    .then((profile) => {
      localStorage.setItem("profile", JSON.stringify(profile.data));
      return profile;
    });
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getPricing() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/subscription-plans`,
    requestOptions
  ).then(handleResponse);
}

function getFaq() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(`${process.env.REACT_APP_API_URL}/faqs`, requestOptions).then(
    handleResponse
  );
}

function getMembership() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/customer/current/memberships`,
    requestOptions
  ).then(handleResponse);
}

function editBuyOrder(data) {
  const requestOptions = {
    //method: 'PUT',
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/user/editbuyorder`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

/* function getCardCategories() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/card-category`, requestOptions).then(handleResponse);
}

function getCardCategoriesImages(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/card-category/images/${id}`, requestOptions).then(handleResponse);
} */

function handleResponse(response) {
  //console.log(response);
  return response.text().then((text) => {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      // logout();
      //window.location.reload(true);
    } else {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        // if (response.status === 401) {
        //     // auto logout if 401 response returned from api
        //     logout();
        //     window.location.reload(true);
        // }
        // if (data && data.errors) {
        //
        // }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    }
  });
}
