import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { Button, FormGroup, Form, Input, InputGroup } from "reactstrap";
import { ButtonLoading } from "components/Loading";
import DismissibleAlert from "components/common/DismissibleAlert";
import { userActions } from "../_actions";
import CustomFileUpload from "components/components-overview/CustomFileUpload";
import { toast } from "react-toastify";
import { replace } from "lodash";
class Kyc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirm_password: "",
      submitted: false,
      first_name: "",
      middle_name: "",
      last_name: "",
      dob: "",
      address: "",
      zip: "",
      pan_card_no: "",
      pan_card_no_confirm: "",
      aadhar_no: "",
      aadhar_no_confirm: "",
      doc_type: "Aadhar",
      kyc_type: "Personal",
      phone: "",
      city: "",
      state: "",
      country: "India",
      pan_card_display_image: "",
      adhar_front_display_image: "",
      adhar_back_display_image: "",
      profile_display_image: "",
      pan_card_image: "",
      aadhar_image_front: "",
      aadhar_image_back: "",
      profile_img: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleFilePanCard = this.handleFilePanCard.bind(this);
    this.handleFileAdhaarFrontCard = this.handleFileAdhaarFrontCard.bind(this);
    this.handleFileAdhaarBackCard = this.handleFileAdhaarBackCard.bind(this);
    this.handleFileProfileImgCard = this.handleFileProfileImgCard.bind(this);
  }
  componentDidMount() {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.props.history.push("/login");
    }
  }
  handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    // this.setState({ [name]: value });
    this.setState((state, props) => ({
      [name]: value,
    }));

    console.log(e.target.name, "target");
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });

    const {
      first_name,
      middle_name,
      last_name,
      dob,
      address,
      zip,
      pan_card_no,
      pan_card_no_confirm,
      aadhar_no,
      aadhar_no_confirm,
      doc_type,
      kyc_type,
      phone,
      city,
      state,
      country,
      pan_card_image,
      aadhar_image_front,
      aadhar_image_back,
      profile_img,
    } = this.state;
    if (first_name) {
      console.log("pan_card_image", pan_card_image);

      let post_body = {
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        dob: dob,
        city: city,
        zip: zip,
        address: address,
        pan_card_no: pan_card_no,
        pan_card_no_confirm: pan_card_no_confirm,
        aadhar_no: aadhar_no,
        aadhar_no_confirm: aadhar_no_confirm,
        doc_type: doc_type,
        kyc_type: kyc_type,
        phone: phone,
        state: state,
        country: country,
        pan_card_image: pan_card_image,
        aadhar_image_front: aadhar_image_front,
        aadhar_image_back: aadhar_image_back,
        profile_image: profile_img,
      };

      console.log(post_body, "All Body");
      // this.props.updateKyc(post_body);
    }
  }

  handleFilePanCard(e) {
    e.preventDefault();

    if (e.target.files) {
      let image_base64 = URL.createObjectURL(e.target.files[0]);
      let image_file = e.target.files[0];
      if (image_file.name.match(/.(png|jpeg|jpg)$/i)) {
        this.setState({
          pan_card_image: image_file,
          pan_card_display_image: image_base64,
        });
      } else {
        toast.error("Only png,jpg and jpeg format fonts are allowed");
      }
    }
  }

  handleFileAdhaarFrontCard(e) {
    e.preventDefault();

    if (e.target.files) {
      let image_base64 = URL.createObjectURL(e.target.files[0]);
      let image_file = e.target.files[0];
      if (image_file.name.match(/.(png|jpeg|jpg)$/i)) {
        this.setState({
          aadhar_image_front: image_file,
          adhar_front_display_image: image_base64,
        });
      } else {
        toast.error("Only png,jpg and jpeg format fonts are allowed");
      }
    }
  }

  handleFileAdhaarBackCard(e) {
    e.preventDefault();

    if (e.target.files) {
      let image_base64 = URL.createObjectURL(e.target.files[0]);
      let image_file = e.target.files[0];
      if (image_file.name.match(/.(png|jpeg|jpg)$/i)) {
        this.setState({
          aadhar_image_back: image_file,
          adhar_back_display_image: image_base64,
        });
      } else {
        toast.error("Only png,jpg and jpeg format fonts are allowed");
      }
    }
  }

  handleFileProfileImgCard(e) {
    e.preventDefault();

    if (e.target.files) {
      let image_base64 = URL.createObjectURL(e.target.files[0]);
      let image_file = e.target.files[0];
      if (image_file.name.match(/.(png|jpeg|jpg)$/i)) {
        this.setState({
          profile_img: image_file,
          profile_display_image: image_base64,
        });
      } else {
        toast.error("Only png,jpg and jpeg format fonts are allowed");
      }
    }
  }

  componentDidMount() {
    const { getProfile, profile } = this.props;
    getProfile();
  }

  // static getDerivedStateFromProps(props, state) {
  //   console.log("my props", props);
  //   const { profile } = props;

  //   if (profile) {
  //     let update = {
  //       first_name: profile.first_name,
  //       middle_name: profile.middle_name,
  //       last_name: profile.last_name,
  //       dob: profile.dob,
  //       address: profile.address,
  //       zip: profile.zip_code,

  //       pan_card_no: profile.pan_card_no,
  //       pan_card_no_confirm: profile.pan_card_no_confirm,
  //       aadhar_no: profile.aadhar_no,
  //       aadhar_no_confirm: profile.aadhar_no_confirm,
  //       doc_type: profile.doc_type,
  //       kyc_type: profile.kyc_type,

  //       phone: profile.phone,
  //       city: profile.city,
  //       state: profile.state,
  //       country: profile.country,
  //       pan_card_image: profile.pan_card_image,

  //       aadhar_image_front: profile.aadhar_image_front,
  //       aadhar_image_back: profile.aadhar_image_back,
  //       pan_card_image: profile.pan_card_image,
  //       profile_img: profile.profile_img,

  //       pan_card_display_image: profile.pan_card_display_image,
  //       adhar_front_display_image: profile.adhar_front_display_image,
  //       adhar_back_display_image: profile.adhar_back_display_image,
  //       profile_display_image: profile.profile_display_image,
  //       kyc_filled: profile.kyc_filled,
  //     };

  //     return update;
  //   }
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState !== nextProps.profile) {
      return {
        ...nextProps.profile,
      };
    }
    return null;
  }

  render() {
    const { loggingIn, alert, profile_loading, loggedIn } = this.props;

    var {
      first_name,
      middle_name,
      last_name,
      dob,
      address,
      zip,
      pan_card_no,
      pan_card_no_confirm,
      aadhar_no,
      aadhar_no_confirm,
      doc_type,
      kyc_type,
      kyc_filled,
      phone,
      city,
      state,
      country,
      pan_card_image,
      aadhar_image_front,
      aadhar_image_back,
      profile_img,
      submitted,
      pan_card_display_image,
      adhar_front_display_image,
      adhar_back_display_image,
      profile_display_image,
    } = this.state;

    return (
      <div className="container">
        <div className="kyc-part">
          <div className="panel-heading">
            <h2 className="panel-title">KYC</h2>
          </div>
          <div className="kyc-part-box">
            <div className="step-header">
              <div className="col-one">
                <i className="fa fa-check-circle"></i>
                <span color="#ffffff">Email</span>
              </div>
              <div className="col-two">
                <i className="fa fa-check-circle"></i>
                <span color="#ffffff">Security</span>
              </div>
              <div className="col-three">
                <i className="fa fa-adjust"></i>
                <span color="#ffffff">Welcome</span>
              </div>
            </div>
          </div>
          <div className="kyc-form-col">
            <h3>Personal Info</h3>

            <div className="flash-message">
              {alert.message && (
                <DismissibleAlert message={alert.message} theme={alert.type} />
              )}
            </div>

            <Form
              className="form form-horizontal"
              role="form"
              name="form"
              onSubmit={this.handleSubmit}
            >
              <div className="slelect-part">
                <label>TYPE OF KYC</label>
                <select
                  disabled={kyc_filled}
                  name="kyc_type"
                  value={kyc_type}
                  onChange={this.handleChange}
                >
                  {/* <option value="">Select Type</option> */}
                  <option value="Personal">Personal</option>
                  <option value="Company">Company</option>
                </select>

                {submitted && !kyc_type && (
                  <div className="invalid-feedback d-block">
                    kyc type is required
                  </div>
                )}
              </div>

              <div className="text-part">
                <label>FIRST NAME *</label>
                <input
                  type="text"
                  className="input-col"
                  name="first_name"
                  value={first_name}
                  onChange={this.handleChange}
                />
                {submitted && !first_name && (
                  <div className="invalid-feedback d-block">
                    first name is required
                  </div>
                )}
              </div>
              <div className="text-part">
                <label>MIDDLE NAME</label>
                <input
                  type="text"
                  className="input-col"
                  disabled={kyc_filled}
                  name="middle_name"
                  value={middle_name}
                  onChange={this.handleChange}
                />
                {submitted && !middle_name && (
                  <div className="invalid-feedback d-block">
                    Middel name is required
                  </div>
                )}
              </div>
              <div className="text-part">
                <label>LAST NAME</label>
                <input
                  type="text"
                  className="input-col"
                  disabled={kyc_filled}
                  name="last_name"
                  value={last_name}
                  onChange={this.handleChange}
                />
                {submitted && !last_name && (
                  <div className="invalid-feedback d-block">
                    last name is required
                  </div>
                )}
              </div>
              <div className="text-part">
                <label>DATE OF BIRTH (DD-MM-YYYY) *</label>
                <input
                  type="text"
                  className="input-col"
                  disabled={kyc_filled}
                  name="dob"
                  value={dob}
                  onChange={this.handleChange}
                />
                {submitted && !dob && (
                  <div className="invalid-feedback d-block">
                    date of birth is required
                  </div>
                )}
              </div>
              <div className="text-part">
                <label>ADDRESS *</label>
                <textarea
                  className="text-area"
                  disabled={kyc_filled}
                  name="address"
                  value={address}
                  onChange={this.handleChange}
                ></textarea>
                {submitted && !address && (
                  <div className="invalid-feedback d-block">
                    address is required
                  </div>
                )}
              </div>

              <div className="slelect-part">
                <label>COUNTRY</label>
                <select
                  name="country"
                  disabled={kyc_filled}
                  value={country}
                  onChange={this.handleChange}
                >
                  <option value="India">India</option>
                </select>
                {submitted && !country && (
                  <div className="invalid-feedback d-block">
                    country is required
                  </div>
                )}
              </div>

              <div className="text-part">
                <label>STATE *</label>
                <input
                  type="text"
                  disabled={kyc_filled}
                  className="input-col"
                  name="state"
                  value={state}
                  onChange={this.handleChange}
                />
                {submitted && !state && (
                  <div className="invalid-feedback d-block">
                    state is required
                  </div>
                )}
              </div>

              <div className="text-part">
                <label>CITY *</label>
                <input
                  type="text"
                  disabled={kyc_filled}
                  className="input-col"
                  name="city"
                  value={city}
                  onChange={this.handleChange}
                />
                {submitted && !city && (
                  <div className="invalid-feedback d-block">
                    city is required
                  </div>
                )}
              </div>

              <div className="text-part">
                <label>PIN/ZIP CODE *</label>
                <input
                  type="text"
                  disabled={kyc_filled}
                  className="input-col"
                  name="zip"
                  value={zip}
                  onChange={this.handleChange}
                />
                {submitted && !zip && (
                  <div className="invalid-feedback d-block">
                    pincode is required
                  </div>
                )}
              </div>

              <div className="text-part">
                <label>CONTACT NO. *</label>
                <input
                  type="text"
                  disabled={kyc_filled}
                  className="input-col"
                  name="phone"
                  value={phone}
                  onChange={this.handleChange}
                />
                {submitted && !phone && (
                  <div className="invalid-feedback d-block">
                    Phone number is required
                  </div>
                )}
              </div>

              <h3>Pan Card</h3>

              <div className="text-part">
                <label>PAN NUMBER *</label>
                <input
                  type="text"
                  disabled={kyc_filled}
                  className="input-col"
                  name="pan_card_no"
                  value={pan_card_no}
                  onChange={this.handleChange}
                />
                {submitted && !pan_card_no && (
                  <div className="invalid-feedback d-block">
                    Pan card number is required
                  </div>
                )}
              </div>

              {kyc_filled == false ? (
                <div className="text-part">
                  <label>RE-ENTER PAN NUMBER *</label>
                  <input
                    type="text"
                    disabled={kyc_filled}
                    className="input-col"
                    name="pan_card_no_confirm"
                    value={pan_card_no_confirm}
                    onChange={this.handleChange}
                  />
                  {submitted && !pan_card_no_confirm && (
                    <div className="invalid-feedback d-block">
                      Confirm Pan card number is required
                    </div>
                  )}
                </div>
              ) : null}

              {kyc_filled == false ? (
                <div tabIndex="0" className="dropzone m-b-3x" accept="image/*">
                  <div className="sample">
                    {this.state.pan_card_display_image != "" ? (
                      <img src={pan_card_display_image} alt="File Uploader" />
                    ) : (
                      <img
                        src="https://media.wazirx.com/media/kyc/sample_pan_card.jpeg"
                        alt="File Uploader"
                      />
                    )}
                  </div>
                  <div className="sc-bdVaJa sc-kfGgVZ haTomx">
                    <div tabIndex="-3">
                      <span className="sc-bwzfXH eJeYEz">Upload PAN Card</span>
                      <span className="sc-bwzfXH gQjCHh">
                        On desktop, simply drag and drop your file in this
                        field. Acceptable formats: JPEG or PNG.
                      </span>
                    </div>

                    <div
                      className="file-upload-icon"
                      style={{ position: "relative" }}
                    >
                      <CustomFileUpload
                        handleFileChange={this.handleFilePanCard}
                        accept=".png,.jpeg,.jpg"
                      />
                      <i className="fa fa-upload"></i>
                    </div>
                  </div>
                </div>
              ) : null}

              <h3>Document Type</h3>

              <div className="slelect-part">
                <label>DOCUMENT TYPE *</label>
                <select
                  disabled={kyc_filled}
                  name="doc_type"
                  value={doc_type}
                  onChange={this.handleChange}
                >
                  <option value="Aadhar">Aadhar</option>
                </select>
                {submitted && !doc_type && (
                  <div className="invalid-feedback d-block">
                    Document type is required
                  </div>
                )}
              </div>

              <div className="text-part">
                <label>AADHAAR NUMBER *</label>
                <input
                  disabled={kyc_filled}
                  type="text"
                  className="input-col"
                  name="aadhar_no"
                  value={aadhar_no}
                  onChange={this.handleChange}
                />
                {submitted && !aadhar_no && (
                  <div className="invalid-feedback d-block">
                    Adhaar card number is required
                  </div>
                )}
              </div>

              {kyc_filled == false ? (
                <div className="text-part">
                  <label>RE-ENTER AADHAAR NUMBER *</label>
                  <input
                    disabled={kyc_filled}
                    type="text"
                    className="input-col"
                    name="aadhar_no_confirm"
                    value={aadhar_no_confirm}
                    onChange={this.handleChange}
                  />
                  {submitted && !aadhar_no_confirm && (
                    <div className="invalid-feedback d-block">
                      Confirm Adhaar card number is required
                    </div>
                  )}
                </div>
              ) : null}

              {kyc_filled == false ? (
                <>
                  <div
                    tabIndex="0"
                    className="dropzone m-b-3x"
                    accept="image/*"
                  >
                    <div className="sample">
                      {this.state.adhar_front_display_image != "" ? (
                        <img
                          src={adhar_front_display_image}
                          alt="File Uploader"
                        />
                      ) : (
                        <img
                          src={require("assets/kyc/sample_aadhaar_card_front.jpeg")}
                          alt="File Uploader"
                        />
                      )}
                    </div>
                    <div className="sc-bdVaJa sc-kfGgVZ haTomx">
                      <div tabIndex="-3">
                        <span className="sc-bwzfXH eJeYEz">
                          Upload Frot of Aadhaar Card
                        </span>
                        <span className="sc-bwzfXH gQjCHh">
                          On desktop, simply drag and drop your file in this
                          field. Acceptable formats: JPEG or PNG.
                        </span>
                      </div>

                      <div
                        className="file-upload-icon"
                        style={{ position: "relative" }}
                      >
                        <CustomFileUpload
                          handleFileChange={this.handleFileAdhaarFrontCard}
                          accept=".png,.jpeg,.jpg"
                        />
                        <i className="fa fa-upload"></i>
                      </div>
                    </div>
                  </div>

                  <div
                    tabIndex="0"
                    className="dropzone m-b-3x"
                    accept="image/*"
                  >
                    <div className="sample">
                      {this.state.adhar_back_display_image != "" ? (
                        <img
                          src={adhar_back_display_image}
                          alt="File Uploader"
                        />
                      ) : (
                        <img
                          src={require("assets/kyc/sample_aadhaar_card_front.jpeg")}
                          alt="File Uploader"
                        />
                      )}
                    </div>
                    <div className="sc-bdVaJa sc-kfGgVZ haTomx">
                      <div tabIndex="-3">
                        <span className="sc-bwzfXH eJeYEz">
                          Upload back of Aadhaar Card
                        </span>
                        <span className="sc-bwzfXH gQjCHh">
                          On desktop, simply drag and drop your file in this
                          field. Acceptable formats: JPEG or PNG.
                        </span>
                      </div>

                      <div
                        className="file-upload-icon"
                        style={{ position: "relative" }}
                      >
                        <CustomFileUpload
                          handleFileChange={this.handleFileAdhaarBackCard}
                          accept=".png,.jpeg,.jpg"
                        />
                        <i className="fa fa-upload"></i>
                      </div>
                    </div>
                  </div>

                  <div
                    tabIndex="0"
                    className="dropzone m-b-3x"
                    accept="image/*"
                  >
                    <div className="sample">
                      {this.state.profile_display_image != "" ? (
                        <img src={profile_display_image} alt="File Uploader" />
                      ) : (
                        <img
                          src={require("assets/kyc/document_selfie.jpg")}
                          alt="File Uploader"
                        />
                      )}
                    </div>
                    <div className="sc-bdVaJa sc-kfGgVZ haTomx">
                      <div tabIndex="-3">
                        <span className="sc-bwzfXH eJeYEz">
                          Upload selfie while holding your Aadhaar Card
                        </span>
                        <span className="sc-bwzfXH gQjCHh">
                          On desktop, simply drag and drop your file in this
                          field. Acceptable formats: JPEG or PNG.
                        </span>
                      </div>

                      <div
                        className="file-upload-icon"
                        style={{ position: "relative" }}
                      >
                        <CustomFileUpload
                          handleFileChange={this.handleFileProfileImgCard}
                          accept=".png,.jpeg,.jpg"
                        />
                        <i className="fa fa-upload"></i>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {kyc_filled == false ? (
                <div className="kyc-form-col">
                  <h3>
                    <strong>Ready to submit your application?</strong>
                  </h3>
                  <p>
                    Please verify the details you’re submitting. Once submitted,
                    you won’t be able to change it unless you Contact us
                  </p>
                  <Button
                    className="sc-dnqmqq ehbjsS"
                    type="submit"
                    disabled={loggingIn}
                  >
                    {loggingIn ? (
                      <ButtonLoading showText={true} />
                    ) : (
                      `Submit for verification`
                    )}
                  </Button>
                </div>
              ) : (
                <div className="kyc-form-col">
                  <h3>
                    <strong>Application has been submitted already.</strong>
                  </h3>
                  <p>
                    Please wait until admin approve the KYC or reject , after
                    that you can update the details again.
                  </p>
                  <Button
                    className="sc-dnqmqq ehbjsS"
                    type="submit"
                    disabled={true}
                  >
                    {loggingIn ? (
                      <ButtonLoading showText={true} />
                    ) : (
                      `Awaiting for Approval`
                    )}
                  </Button>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  const { loggedIn } = state.authentication;
  const { item: profile } = state.profile;
  return { loggedIn, alert, profile };
}

const actionCreators = {
  getProfile: userActions.getProfile,
  updateKyc: userActions.updateKyc,
};

export default connect(mapState, actionCreators)(Kyc);
