import { userConstants } from '../_constants';

const initialState = {
  items: [],
  loading: false,
  error: null
};

export function currency_list(state = initialState, action) {
  switch (action.type) {
    case userConstants.USER_CURRENCY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case userConstants.USER_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.items
      };
    case userConstants.USER_CURRENCY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        items : []
      };
   
   
    default:
      return state
  }
}
