import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { config } from './config.reducer';
import { profile } from './profile.reducer';
import { alert } from './alert.reducer';
import { exchange } from './exchange.reducer';
import { currency_list } from './currency.reducer';


const rootReducer = combineReducers({
  authentication,
  registration,
  config,
  profile,
  alert,
  exchange,
  currency_list
});

export default rootReducer;
