import React from "react";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import { Form } from "reactstrap";

class UserOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: "",
      },
      first_name: "",
      last_name: "",
      phone: "",
      country: "",
      city: "",
      open: false,
      submitted: false,
      active_tab: "open",
      modalOpen: false,
      at_price: "",
      amount: "",
      total: "",
      order_id: "",
      type: "",
      asset_id: "",
      pair_id: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickTab = this.handleClickTab.bind(this);

    this.handleClickEditOrder = this.handleClickEditOrder.bind(this);
    this.moddleToggle = this.moddleToggle.bind(this);
  }

  handleClickTab(tab_name) {
    this.setState({
      active_tab: tab_name,
    });
  }

  componentDidMount() {
    const { fetched_o, fetched_c, openOrdersAll, completedOrdersAll } =
      this.props;
    if (fetched_o === false) {
      openOrdersAll();
    }
    if (fetched_c === false) {
      completedOrdersAll();
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { order_id, total, at_price, amount, type, asset_id, pair_id } =
      this.state;

    let postData = {
      id: Number(order_id),
      asset_id: Number(asset_id),
      pair_id: Number(pair_id),
      at_price: Number(at_price),
      amount: Number(amount),
      total: Number(total),
    };

    if (type == "buy") {
      console.log(postData);
      this.props.editBuyOrder(postData);
    }
    if (type == "sell") {
      this.props.editSellOrder(postData);
    }
  }

  handleClickEditOrder(order_detail) {
    console.log("order_detail", order_detail);
    this.setState({
      modalOpen: true,
      order_id: order_detail.id,
      total: order_detail.total,
      at_price: order_detail.at_price,
      amount: order_detail.amount,
      type: order_detail.type,
      asset_id: order_detail.asset_id,
      pair_id: order_detail.pair_id,
    });
  }

  moddleToggle() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  }

  render() {
    const {
      completed_orders_list_all,
      open_orders_list_all,
      loading_c,
      loading_o,
    } = this.props;
    const { at_price, amount, total } = this.state;

    return (
      <>
        <div className="container history-pages">
          <ul className="nav nav-pills" role="tablist">
            <li
              className={
                " " + (this.state.active_tab == "open" ? "active" : "")
              }
            >
              <a onClick={() => this.handleClickTab("open")}>Open Orders</a>
            </li>
            <li
              className={
                " " + (this.state.active_tab == "completed" ? "active" : "")
              }
            >
              <a onClick={() => this.handleClickTab("completed")}>
                Completed Orders
              </a>
            </li>
          </ul>
          <div className="panel panel-default">
            <div className="panel-body">
              <div className="col-xs-24">
                <form
                  id="order_history"
                  action=""
                  acceptCharset="UTF-8"
                  method="get"
                >
                  <input name="utf8" type="hidden" value="✓" />
                  <div className="col-xs-3">
                    <select name="order_type" id="order_type">
                      <option value="">All Type</option>
                      <option value="bid">Buy</option>
                      <option value="ask">Sell</option>
                    </select>
                  </div>

                  <div className="col-xs-3">
                    <select name="order_state" id="order_state">
                      <option value="">All State</option>
                      <option value="wait">Active</option>
                      <option value="done">Done</option>
                      <option value="cancel">Cancelled</option>
                    </select>
                  </div>

                  <div className="col-xs-3">
                    <select name="order_pairs" id="order_pairs">
                      <option value="">All Pairs</option>
                      <option value="ethbtc">ETH/BTC</option>
                      <option value="ltcbtc">LTC/BTC</option>
                      <option value="dashbtc">DASH/BTC</option>
                      <option value="xrpbtc">XRP/BTC</option>
                      <option value="bchabcbtc">BCHABC/BTC</option>
                      <option value="xmrbtc">XMR/BTC</option>
                      <option value="zecbtc">ZEC/BTC</option>
                      <option value="ltceth">LTC/ETH</option>
                      <option value="dasheth">DASH/ETH</option>
                      <option value="xrpeth">XRP/ETH</option>
                      <option value="xmreth">XMR/ETH</option>
                      <option value="zeceth">ZEC/ETH</option>
                      <option value="btcusdt">BTC/USDT</option>
                      <option value="ethusdt">ETH/USDT</option>
                      <option value="ltcusdt">LTC/USDT</option>
                      <option value="dashusdt">DASH/USDT</option>
                      <option value="bchabcusdt">BCHABC/USDT</option>
                      <option value="xrpusdt">XRP/USDT</option>
                      <option value="xmrusdt">XMR/USDT</option>
                      <option value="zecusdt">ZEC/USDT</option>
                      <option value="usdcusdt">USDC/USDT</option>
                    </select>
                  </div>

                  <div className="col-xs-3 filterBtn">
                    <input type="submit" name="commit" value="Filter" />
                  </div>
                </form>
              </div>
            </div>
          </div>
          {this.state.active_tab == "open" ? (
            <div className="panel panel-default mt-4" id="orders">
              <div className="panel-body">
                <div className="col-xs-24">
                  <table className="table table-condensed table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Type</th>
                        <th>State</th>
                        <th>Market</th>
                        <th>Price</th>
                        <th>Volume</th>
                        <th>Avg. Price</th>
                        <th>Fullfilled</th>
                        <th>Created At</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {loading_o && "Loading..."}
                      {open_orders_list_all &&
                        open_orders_list_all.map((o_order, index) => (
                          <tr key={index}>
                            <td>{o_order.pair_id}</td>
                            <td>{o_order.type}</td>
                            <td>Open</td>
                            <td>{o_order.pair_code}</td>
                            <td>{o_order.at_price}</td>
                            <td>{o_order.amount}</td>
                            <td>0.004</td>
                            <td>0.9995</td>
                            <td>2021-07-02 14:58:22</td>
                            <td>
                              <button
                                onClick={() =>
                                  this.handleClickEditOrder(o_order)
                                }
                                className="btn btn-success btn-sm mx-2"
                              >
                                <span color="#ffffff" className="hdlwFq">
                                  EDIT
                                </span>
                              </button>

                              <button className="btn btn-warning btn-sm">
                                <span color="#ffffff" className="hdlwFq">
                                  CANCEL
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}

                      {!open_orders_list_all ? (
                        <tr>
                          <td colSpan={10} align="center">
                            No record found
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}

          {this.state.active_tab == "completed" ? (
            <div className="panel panel-default mt-4" id="orders">
              <div className="panel-body">
                <div className="col-xs-24">
                  <table className="table table-condensed table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Type</th>
                        <th>State</th>
                        <th>Market</th>
                        <th>Price</th>
                        <th>Volume</th>
                        <th>Avg. Price</th>
                        <th>Fullfilled</th>
                        <th>Created At</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {loading_c && "Loading..."}
                      {completed_orders_list_all &&
                        completed_orders_list_all.map((c_order) => (
                          <tr>
                            <td>{c_order.pair_id}</td>
                            <td>{c_order.type}</td>
                            <td>Open</td>
                            <td>{c_order.pair_code}</td>
                            <td>{c_order.at_price}</td>
                            <td>{c_order.amount}</td>
                            <td>0.004</td>
                            <td>0.9995</td>
                            <td>2021-07-02 14:58:22</td>
                            <td>
                              <button className="btn btn-success btn-sm mx-2">
                                <span color="#ffffff" className="hdlwFq">
                                  EDIT
                                </span>
                              </button>

                              <button className="btn btn-warning btn-sm">
                                <span color="#ffffff" className="hdlwFq">
                                  CANCEL
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}

                      {!completed_orders_list_all ? (
                        <tr>
                          <td colSpan={10} align="center">
                            No record found
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div
          className={"modal " + (this.state.modalOpen == true ? "show" : "")}
          tabindex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Order</h5>
                <button
                  onClick={this.moddleToggle}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Form
                  className="sc-gPEVay iOMNyh"
                  role="form"
                  name="form"
                  onSubmit={this.handleSubmit}
                >
                  <div className="sc-bdVaJa sc-dfVpRl dDBViW">
                    <div className="sc-bdVaJa sc-iyvyFf bHMydW">
                      <div className="sc-bdVaJa sc-jtRfpW fQRysy">
                        <div className="sc-bdVaJa sc-dqBHgY gCVUaG">
                          <span className="sc-bwzfXH hjQJfp">AT PRICE</span>
                          <span className="sc-bwzfXH hSygGi">inr</span>
                        </div>
                        <input
                          type="text"
                          autoCapitalize="off"
                          autoComplete="off"
                          spellCheck="false"
                          autoCorrect="off"
                          inputMode="decimal"
                          className="sc-kTUwUJ cKOnhg"
                          name="at_price"
                          id="at_price"
                          value={at_price}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div>
                      <span
                        className="cur-ptr sc-bwzfXH iMHVtk"
                        color="#55bd6c"
                      >
                        Lowest Price
                      </span>
                    </div>
                  </div>
                  <div className="sc-bdVaJa sc-dfVpRl dDBViW">
                    <div className="sc-bdVaJa sc-iyvyFf bHMydW">
                      <div className="sc-bdVaJa sc-jtRfpW fQRysy">
                        <div className="sc-bdVaJa sc-dqBHgY gCVUaG">
                          <span className="sc-bwzfXH hjQJfp">Amount</span>
                          <span className="sc-bwzfXH hSygGi">btc</span>
                        </div>
                        <input
                          type="text"
                          autoCapitalize="off"
                          autoComplete="off"
                          spellCheck="false"
                          autoCorrect="off"
                          className="sc-kTUwUJ cKOnhg"
                          name="amount"
                          id="amount"
                          value={amount}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="sc-bdVaJa sc-dfVpRl dDBViW">
                    <div className="sc-bdVaJa sc-iyvyFf bHMydW">
                      <div className="sc-bdVaJa sc-jtRfpW fQRysy">
                        <div className="sc-bdVaJa sc-dqBHgY gCVUaG">
                          <span className="sc-bwzfXH hjQJfp">Total</span>
                          <span className="sc-bwzfXH hSygGi">inr</span>
                        </div>
                        <input
                          type="text"
                          autoCapitalize="off"
                          autoComplete="off"
                          spellCheck="false"
                          autoCorrect="off"
                          inputMode="decimal"
                          className="sc-kTUwUJ cKOnhg"
                          name="total"
                          id="total"
                          value={total}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="sc-bdVaJa sc-iujRgT cFmsHG">
                        <div
                          width="calc(100% - 125px)"
                          className="sc-gojNiO gItioN"
                        >
                          <svg
                            viewBox="0 0 43 43"
                            width="12"
                            height="12"
                            fill="rgba(34, 35, 38, 0.38)"
                            className="sc-exAgwC kvVgIg"
                          >
                            <path d="M31.969 27c1.688 0 3-1.313 3-3s-1.313-3-3-3-3 1.313-3 3 1.313 3 3 3zM24 31.969v-15.938h19.969v15.938h-19.969zM42 36v1.969c0 2.156-1.875 4.031-4.031 4.031h-27.938c-2.25 0-4.031-1.875-4.031-4.031v-27.938c0-2.156 1.781-4.031 4.031-4.031h27.938c2.156 0 4.031 1.875 4.031 4.031v1.969h-18c-2.25 0-4.031 1.875-4.031 4.031v15.938c0 2.156 1.781 4.031 4.031 4.031h18z"></path>
                          </svg>
                          <span
                            color="rgba(34, 35, 38, 0.38)"
                            fontWeight="600"
                            fontSize="10px"
                            className="sc-cQFLBn hcywEG"
                          >
                            ₹0
                          </span>
                        </div>
                        <div className="sc-bdVaJa sc-esOvli evcoVc">
                          <button
                            type="button"
                            tabIndex="0"
                            className="sc-iuJeZd bippEu"
                          >
                            25%
                          </button>
                          <button
                            type="button"
                            tabIndex="0"
                            className="sc-iuJeZd bippEu"
                          >
                            50%
                          </button>
                          <button
                            type="button"
                            tabIndex="0"
                            className="sc-iuJeZd bippEu"
                          >
                            75%
                          </button>
                          <button
                            type="button"
                            tabIndex="0"
                            className="sc-iuJeZd bippEu"
                          >
                            100%
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  onClick={this.handleSubmit}
                  className="btn btn-primary"
                >
                  Save changes
                </button>
                <button
                  type="button"
                  onClick={this.moddleToggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  const { loggedIn } = state.authentication;
  const {
    items: completed_orders_list_all,
    loading_c,
    fetched_c,
  } = state.exchange.completed_orders_list_all;
  const {
    items: open_orders_list_all,
    loading_o,
    fetched_o,
  } = state.exchange.open_orders_list_all;
  return {
    loggedIn,
    alert,
    completed_orders_list_all,
    open_orders_list_all,
    loading_c,
    fetched_c,
    loading_o,
    fetched_o,
  };
}

const actionCreators = {
  completedOrdersAll: userActions.completedOrdersAll,
  openOrdersAll: userActions.openOrdersAll,
  editBuyOrder: userActions.editBuyOrder,
};
export default connect(mapState, actionCreators)(UserOrders);
