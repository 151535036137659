import { combineReducers } from 'redux';
import pair from './exchange/pair.reducer';
import pair_data from './exchange/pairdata.reducer';
import completed_orders_list from './exchange/completedorders.reducer';
import open_orders_list from './exchange/openorders.reducer';
import completed_orders_list_all from './exchange/completedordersall.reducer';
import open_orders_list_all from './exchange/openordersall.reducer';
import orderbooklist from './exchange/orderbooklist.reducer';


export const exchange = combineReducers({
  pair,
  pair_data,
  completed_orders_list,
  open_orders_list,
  completed_orders_list_all,
  open_orders_list_all,
  orderbooklist
});

