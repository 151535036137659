import React from "react";
import { connect } from 'react-redux';
import DismissibleAlert from "components/common/DismissibleAlert";
import { userActions } from '../_actions';

class CurrencyPreference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      submitted: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePreference = this.handleChangePreference.bind(this);
    
  }

  componentDidMount() {
    const { loading,getCurrency } = this.props;
       getCurrency();
   }


  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
}

handleSubmit(e) {

    let user = JSON.parse(localStorage.getItem('user'));
    let id;
    if (user && user.user_id) {
      id = user.user_id;
    }
    console.log('logged in user',user);

    e.preventDefault();
    this.setState({ submitted: true });
    const { first_name,last_name,phone,country,city } = this.state;
    if (id ) {
      var postData= {'id':id,'first_name' : first_name,'last_name' : last_name,'phone': phone,'country':country,'city':city };
        this.props.editProfile(postData);
    }
}


handleChangePreference(asset_id) {

  const { loading,getCurrency } = this.props;
    if(asset_id!='')
    {
      this.props.editCurrency(asset_id);
      setTimeout(function() {
          getCurrency();
      }, 1000);

    }


}



  render() {


    const { user,alert,currency_list,loading} = this.props;

    console.log('currency_list',currency_list);

    return (
		<>
				<div className='container'>
        <div className="panel panel-default">
            <div className="panel-heading"><h2 className="panel-title">Update Currency Preference</h2></div>
          <div className="panel-body panel-currencies">

            <div className='row'>
              <div className="col-xs-8" id="guide">
                <h3><i className="fa fa-user"></i><span>{user.email}</span></h3>
                <p className="text-ignore">Admin and revise your profile.</p>
                <ul className="list-group">
                    <span className="label label-warning">{user.first_name} {user.last_name}</span>
                </ul>
                <p className="text-warning">
                  Please set your currency preference here, so that you can trading fast.
                </p>
              </div>

              <div className="col-xs-16" id="content">
                <div className="flash-message">
                {alert.message &&
                    <DismissibleAlert message={alert.message} theme={alert.type} />
                  }
                </div>

                
               <div>
                  <ul>
                    
                  {currency_list.map((currency, i) => (
								<li onClick={() => this.handleChangePreference(currency.asset_id)}  className="prefs-list-item prefs-list-item1 d-flex justify-content-between">
                     <div className="prefs-list-item-text">
                       <img src="https://media.wazirx.com/media/usdt/84.png" alt="usdt" style={{ marginRight: 16 }} height="24" width="24" class="sc-jSgvzq enlCit" /> {currency.asset_code}</div>
                     <div className="prefs-list-item-state">
                       <input name="asset_id" id="asset_id" type="radio" checked={currency.is_selected}  />
                       </div>
                </li>
								))}
                
                
                  </ul>
                </div>






              </div>


            </div>

          </div>
        </div>

      </div>
		</>
    );
  }
}



function mapState(state) {
  const { alert } = state;
  const { authentication } = state;
  const { user } = authentication;
  const { loggedIn } = state.authentication;
  const { items:currency_list,loading } = state.currency_list;
  return {user, loggedIn, alert ,currency_list,loading };

}

const actionCreators = {
  getCurrency: userActions.getCurrency,
  editCurrency: userActions.editCurrency
};
export default connect(mapState,actionCreators)(CurrencyPreference);