import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// reactstrap components
import { Form, Input } from "reactstrap";
import DismissibleAlert from "../../components/common/DismissibleAlert";
import { userActions } from "../../_actions";
import { service } from "../../_services/service";

// core components
import { Loading } from "components/Loading";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        password: "",
        c_password: "",
        referal_code: "",
        terms: "",
      },
      error: {
        email: "",
      },
      model: {
        open: false,
      },
      page: {},
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount() {
    console.log("all props", this.props);
    const { loggedIn } = this.props;
    console.log(loggedIn, "======loggedIn");
    if (loggedIn) {
      this.props.history.push("/user-settings");
    }
  }

  onOpenModal = (url) => {
    this.setState({
      model: {
        open: true,
        url: url,
      },
    });

    const post = this.state.page[url];
    if (post == null) {
      const { page } = this.state;
      service.getSimple(url, {}).then(
        (detail) => {
          this.setState({
            page: { ...page, [url]: detail.data },
          });
        },
        (error) => {
          console.log(error.toString());
        }
      );
    }
  };

  onCloseModal = () => {
    this.setState({ model: { open: false } });
  };

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleCheck(event) {
    var { name, value } = event.target;
    if (!event.target.checked) {
      value = null;
    }

    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleSubmit(event) {
    console.log("check");
    event.preventDefault();

    this.setState({
      submitted: true,
      error: {
        email: "",
      },
    });
    const { user } = this.state;
    const email = user.email;
    if (email) {
      console.log("checkthis");
      var domains = [
        "gmail.com",
        "yahoo.com",
        "hotmail.com",
        "yahoo.co.in",
        "aol.com",
        "abc.com",
        "xyz.com",
        "pqr.com",
        "rediffmail.com",
        "live.com",
        "outlook.com",
        "me.com",
        "msn.com",
        "ymail.com",
      ];

      var regex = /^[\w-+]+(?:\.[\w-]+)*@(.*\..{2,})$/i;
      var matches = regex.exec(email);
      var emailDomain = email.substring(email.indexOf("@") + 1);
      // Assert that address is an .com email address
      if (matches === null) {
        this.setState({
          error: {
            email: "Entered Email is not valid.",
          },
        });
        return false;
      }

      // return true if domain is in whitelist, false otherwise

      var checkEmail = domains.some((value) => {
        return value == emailDomain;
      });

      if (checkEmail) {
        this.setState({
          error: {
            email: "",
          },
        });
        // console.log(checkEmail);
      } else {
        this.setState({
          error: {
            email: "Please use only work email.",
          },
        });
        return false;
      }
      // if (domains.indexOf(matches[1]) !== -1) {
      //   this.setState({
      //     error: {
      //       email: "Please use only work email.",
      //     },
      //   });
      //   return false;
      // }
    }

    if (
      user.firstName &&
      user.lastName &&
      user.email &&
      user.password &&
      user.c_password &&
      user.userName
    ) {
      this.props.register(user);
    }
  }

  renderModal = () => {
    //console.log(this.state.model.url);
    if (this.state.model.url !== null) {
      const post = this.state.page[this.state.model.url];
      return (
        <>
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              {post ? post.page_title : "Title"}
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.onCloseModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {post ? (
              <div
                dangerouslySetInnerHTML={{ __html: post.page_description }}
              ></div>
            ) : (
              <Loading />
            )}
          </div>
        </>
      );
    }
  };

  render() {
    const { registering, alert } = this.props;
    const { user, error, model, submitted } = this.state;
    return (
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            {/* <div className="col-md-6 text-center mb-5">
              <img src={require("assets/images/logo/logo.png")} alt="Logo" />
            </div> */}
          </div>
          <div className="row">
            {alert.message && (
              <DismissibleAlert
                className="display-linebreak"
                message={alert.message}
                theme={alert.type}
              />
            )}
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5">
              <div className="login-wrap p-4 p-md-5">
                {/* <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-user-o"></span>
                </div> */}
                <h3 className="text-center mb-4">SIGN UP</h3>
                <p>Let’s start by submitting your registration details.</p>
                <Form
                  className="login-form"
                  role="form"
                  name="form"
                  onSubmit={this.handleSubmit}
                >
                  <div className="form-group">
                    <Input
                      size="lg"
                      className={
                        submitted && !user.firstName ? "is-invalid" : ""
                      }
                      placeholder="First Name"
                      id="firstName"
                      type="text"
                      name="firstName"
                      value={user.firstName}
                      onChange={this.handleChange}
                    />
                    {submitted && !user.firstName && (
                      <div className="invalid-feedback d-block">
                        First Name is required
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <Input
                      size="lg"
                      className={
                        submitted && !user.lastName ? "is-invalid" : ""
                      }
                      placeholder="Last Name"
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={user.lastName}
                      onChange={this.handleChange}
                    />
                    {submitted && !user.lastName && (
                      <div className="invalid-feedback d-block">
                        Last Name is required
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <Input
                      size="lg"
                      className={
                        submitted && !user.userName ? "is-invalid" : ""
                      }
                      placeholder="Username"
                      type="text"
                      id="userName"
                      name="userName"
                      value={user.userName}
                      onChange={this.handleChange}
                    />
                    {submitted && !user.userName && (
                      <div className="invalid-feedback d-block">
                        Username is required
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <Input
                      size="lg"
                      className={submitted && !user.email ? "is-invalid" : ""}
                      placeholder="email"
                      type="email"
                      id="email"
                      name="email"
                      value={user.email}
                      onChange={this.handleChange}
                    />
                    {submitted && !user.email && (
                      <div className="invalid-feedback d-block">
                        Email is required
                      </div>
                    )}
                    {error && error.email ? <span>{error.email}</span> : ""}
                  </div>

                  <div className="form-group ">
                    <Input
                      size="lg"
                      id="password"
                      className={
                        submitted && !user.password ? "is-invalid" : ""
                      }
                      placeholder="Password"
                      type="password"
                      autoComplete="off"
                      name="password"
                      value={user.password}
                      onChange={this.handleChange}
                    />
                    {submitted && !user.password && (
                      <div className="invalid-feedback d-block">
                        Password is required
                      </div>
                    )}
                  </div>
                  <div className="form-group ">
                    <Input
                      size="lg"
                      id="password"
                      className={
                        submitted && !user.c_password ? "is-invalid" : ""
                      }
                      placeholder="Confirm Password"
                      type="password"
                      autoComplete="off"
                      name="c_password"
                      value={user.c_password}
                      onChange={this.handleChange}
                    />
                    {submitted && !user.c_password && (
                      <div className="invalid-feedback d-block">
                        Confirm Password is required
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <Input
                      size="lg"
                      id="text"
                      // className={
                      //   submitted && !user.referal_code ? "is-invalid" : ""
                      // }
                      placeholder="Enter referral code (Optional)"
                      type="text"
                      autoComplete="off"
                      name="referal_code"
                      value={user.referal_code}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="form-group d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-primary rounded submit p-3 px-5 "
                    >
                      Sign Up
                    </button>
                  </div>
                  <div className="w-100 text-md-center">
                    <Link to="login">Login</Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  const { registering } = state.registration;
  const { loggedIn } = state.authentication;

  return { registering, alert, loggedIn };
}

const actionCreators = {
  register: userActions.register,
};

export default connect(mapState, actionCreators)(Register);
