import { authHeader } from '../_helpers';

export const service = {
    getSimple,
    getAuth,
    send,
    sendAuth,
    sendAuthForm,
    job_apply,
    pairData,
    getOrderBook
};

function getSimple(url,data) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/${url}`, requestOptions).then(handleResponse);
}
function getOrderBook(url) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/${url}`, requestOptions).then(handleResponse);
}



function pairData(url) {
    const requestOptions = {
        method: 'GET',
    };
    return fetch(`${process.env.REACT_APP_API_URL}/${url}`, requestOptions).then(handleResponse);
}



function getAuth(url,data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
        //body: JSON.stringify(postData)
    };
    
    var final_url = '';
    if (typeof data === 'undefined') {
        final_url = `${process.env.REACT_APP_API_URL}/${url}`;
    } else{
        final_url = `${process.env.REACT_APP_API_URL}/${url}${data}`;
    }

    return fetch(final_url, requestOptions).then(handleResponse);
}

function send(url,method,data) {
    const requestOptions = {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/${url}`, requestOptions).then(handleResponse);
}

function sendAuth(url, method, data) {
    const requestOptions = {
        method: method,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}/${url}`, requestOptions).then(handleResponse);
}

function sendAuthForm(url,formData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: formData
    };

    return fetch(`${process.env.REACT_APP_API_URL}/${url}`, requestOptions).then(handleResponse);
}

function job_apply(url,postData) {
    let formData = new FormData();
    formData.append('linkedin_profile', postData.linkedin_profile);
    formData.append('full_name', postData.full_name);
    formData.append('email', postData.email);
    formData.append('phone', postData.phone);    
    formData.append('resume_pdf', postData.resume_pdf);

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: formData
    };

    return fetch(`${process.env.REACT_APP_API_URL}/${url}`, requestOptions).then(handleResponse);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('profile');
    localStorage.removeItem('userSelectedPlan');
}

function handleResponse(response) {
  //console.log(response);
    return response.text().then(text => {
      if (response.status === 401) {
          // auto logout if 401 response returned from api
          logout();
          window.location.reload(true);
      } else {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            // if (response.status === 401) {
            //     // auto logout if 401 response returned from api
            //     logout();
            //     window.location.reload(true);
            // }
            // if (data && data.errors) {
            //
            // }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
      }

    });
}
