import React,{ useState,useEffect } from "react";

const CustomFileUpload = (props) => {
  
  const [state, setState] = useState({
    input_placeholder: 'Choose file...',
    filename: '',
    file: '',
    file_base64: '',
  });

  const handleFileChange = (e) => {
    e.preventDefault();

    if (e.target.files) {

      let image_base64= URL.createObjectURL(e.target.files[0])
      let image_file  = e.target.files[0];

      setState({
        ...state,
        file_base64: image_base64,
        file: image_file,
        filename: image_file.name
      });
      
      props.handleFileChange(e);
    }
  }

  useEffect(() => {
    if(props.file === ''){
      setState({
        ...state,
        filename: '',
        file: '',
        file_base64: '',
      });
    }
    // eslint-disable-next-line
  }, [props.file])

  return(
    <div className="custom-file" style={{position:'absolute',height:'100%'}}>
      <input type="file" className="custom-file-input" id="customFile2" onChange={handleFileChange} accept={props.accept} />
      <label className="custom-file-label" htmlFor="customFile2" style={{overflow: 'hidden'}}>
        {state.filename ? state.filename : state.input_placeholder}
      </label>
    </div>
  )
};

export default CustomFileUpload;
