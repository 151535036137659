import { commonConstants } from '../../_constants';

const initialState = {
  item: [],
  loading_pd: false,
  fetched_pd: false,
  error_pd: null
};

export default function pair_data(state = initialState, action) {
  switch (action.type) {
    case commonConstants.PAIR_DATA_REQUEST:
      return {
        ...state,
        loading_pd: true,
        error_pd: null
      };
    case commonConstants.PAIR_DATA_SUCCESS:
      return {
        ...state,
        loading_pd: false,
        fetched_pd: true,
        item: action.item
      };
    case commonConstants.PAIR_DATA_FAILURE:
      return {
        ...state,
        loading_pd: false,
        error_pd: action.error,
        item: []
      };
    default:
      return state
  }
}
