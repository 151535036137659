export const commonConstants = {
    GETPRICING_REQUEST: 'PRICING_LIST_REQUEST',
    GETPRICING_SUCCESS: 'PRICING_LIST_SUCCESS',
    GETPRICING_FAILURE: 'PRICING_LIST_FAILURE',
    
    FAQ_REQUEST: 'FAQ_LIST_REQUEST',
    FAQ_SUCCESS: 'FAQ_LIST_SUCCESS',
    FAQ_FAILURE: 'FAQ_LIST_FAILURE',

    PAIR_LIST_REQUEST: 'PAIR_LIST_REQUEST',
    PAIR_LIST_SUCCESS: 'PAIR_LIST_SUCCESS',
    PAIR_LIST_FAILURE: 'PAIR_LIST_FAILURE',

    PAIR_DATA_REQUEST: 'PAIR_DATA_REQUEST',
    PAIR_DATA_SUCCESS: 'PAIR_DATA_SUCCESS',
    PAIR_DATA_FAILURE: 'PAIR_DATA_FAILURE',

    


    SUBSCRIPTION_REQUEST: 'USERS_SUBSCRIPTION_REQUEST',
    SUBSCRIPTION_SUCCESS: 'USERS_SUBSCRIPTION_SUCCESS',
    SUBSCRIPTION_FAILURE: 'USERS_SUBSCRIPTION_FAILURE',

    CARD_CATEGORY_REQUEST: 'CARD_CATEGORY_REQUEST',
    CARD_CATEGORY_SUCCESS: 'CARD_CATEGORY_SUCCESS',
    CARD_CATEGORY_FAILURE: 'CARD_CATEGORY_FAILURE',

    CARD_CATEGORY_IMAGES_REQUEST: 'CARD_CATEGORY_IMAGES_REQUEST',
    CARD_CATEGORY_IMAGES_SUCCESS: 'CARD_CATEGORY_IMAGES_SUCCESS',
    CARD_CATEGORY_IMAGES_LOAD_MORE_SUCCESS: 'CARD_CATEGORY_IMAGES_LOAD_MORE_SUCCESS',
    CARD_CATEGORY_IMAGES_FAILURE: 'CARD_CATEGORY_IMAGES_FAILURE',

    GET_CONFIG_REQUEST: 'GET_CONFIG_REQUEST',
    GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS',
    GET_CONFIG_FAILURE: 'GET_CONFIG_FAILURE',

    COUPON_CODE_APPLY_REQUEST: 'COUPON_CODE_APPLY_REQUEST',
    COUPON_CODE_APPLY_SUCCESS: 'COUPON_CODE_APPLY_SUCCESS',
    COUPON_CODE_APPLY_FAILURE: 'COUPON_CODE_APPLY_FAILURE',
    
    CANDIDATE_ENGAGEMENT_REQUEST: 'CANDIDATE_ENGAGEMENT_REQUEST',
    CANDIDATE_ENGAGEMENT_SUCCESS: 'CANDIDATE_ENGAGEMENT_SUCCESS',
    CANDIDATE_ENGAGEMENT_FAILURE: 'CANDIDATE_ENGAGEMENT_FAILURE',
    
    EARLY_STAGE_APPLY_REQUEST: 'EARLY_STAGE_APPLY_REQUEST',
    EARLY_STAGE_APPLY_SUCCESS: 'EARLY_STAGE_APPLY_SUCCESS',
    EARLY_STAGE_APPLY_RESET: 'EARLY_STAGE_APPLY_RESET',
    EARLY_STAGE_APPLY_FAILURE: 'EARLY_STAGE_APPLY_FAILURE',
    
    TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
    RESIZE_SIDEBAR: 'RESIZE_SIDEBAR',

    RECEIVE_UNSPLASH_SEARCH_IMAGES: 'RECEIVE_UNSPLASH_SEARCH_IMAGES',
    RECEIVE_UNSPLASH_POPULAR_IMAGES: 'RECEIVE_UNSPLASH_POPULAR_IMAGES',
    RECEIVE_UNSPLASH_SEARCH_LOAD_MORE_IMAGES: 'RECEIVE_UNSPLASH_SEARCH_LOAD_MORE_IMAGES',
    RECEIVE_UNSPLASH_POPULAR_LOAD_MORE_IMAGES: 'RECEIVE_UNSPLASH_POPULAR_LOAD_MORE_IMAGES',
    RECEIVE_UNSPLASH_ERRORS: 'RECEIVE_UNSPLASH_ERRORS',

    IMAGE_KEYWORDS_SUGGEST_REQUEST: 'IMAGE_KEYWORDS_SUGGEST_REQUEST',
    IMAGE_KEYWORDS_SUGGEST_SUCCESS: 'IMAGE_KEYWORDS_SUGGEST_SUCCESS',
    IMAGE_KEYWORDS_SUGGEST_FAILURE: 'IMAGE_KEYWORDS_SUGGEST_FAILURE',

    ORDERBOOK_LIST_REQUEST: 'ORDERBOOK_LIST_REQUEST',
    ORDERBOOK_LIST_SUCCESS: 'ORDERBOOK_LIST_SUCCESS',
    ORDERBOOK_LIST_FAILURE: 'ORDERBOOK_LIST_FAILURE',

    
    
};
