import React from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
// import "../../../../src/assets/css/style.css";

// const LoginButton = () => {
//   return (
//     <>
//       <li className="login-button">
//         <Link to="/login">
//           Login <i className="flaticon-right-thin"></i>
//         </Link>
//       </li>
//       <li className="login-button ml-3">
//         <Link to="/signup">
//           Signup <i className="flaticon-right-thin"></i>
//         </Link>
//       </li>
//     </>
//   );
// };

// const LogoutButton = () => {
//   return (
//     <>
//       <li className="login-button  ml-3">
//         <Link to="/user-settings">
//           Dashboard <i className="flaticon-right-thin"></i>
//         </Link>
//       </li>
//       <li className="login-button ml-3">
//         <Link to="/logout">
//           Logout <i className="flaticon-right-thin"></i>
//         </Link>
//       </li>
//     </>
//   );
// };

class DefaultNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseClasses: "",
      collapseOpen: false,
      stickyTop: this.props.headerFixed,
      showMenu: false,
      menu: [
        {
          title: "Exchange",
          Name: "Exchange",
          path: "/exchange",
          active: "exchange",
        },
        {
          title: "P2P",
          Name: "P2P",
          path: "#",
          active: "P2P",
        },
        {
          title: "Dashboard",
          Name: "Dashboard",
          path: "/user-settings",
          active: "user-settings",
        },
        {
          title: "LOGOUT",
          Name: "LOGOUT",
          path: "/logout",
          active: "logout",
        },
      ],
      LoginMenu: [
        {
          title: "Exchange",
          Name: "Exchange",
          path: "/exchange",
          active: "exchange",
        },
        {
          title: "P2P",
          Name: "P2P",
          path: "#",
          active: "P2P",
        },
        {
          title: "LOGIN",
          Name: "LOGIN",
          path: "/login",
          active: "login",
        },
        {
          title: "SIGN UP",
          Name: "SIGN UP",
          path: "/signup",
          active: "signup",
        },
      ],
      activeButton: "",
      // mainMenu: this.props.user == undefined ? this.LoginMenu : this.menu,
      // && this.props.user ? this.LoginMenu : this.menu,
    };

    this.handalActiveButton = this.handalActiveButton.bind(this);
  }

  componentDidMount() {
    this.setState({
      mainMenu: `${
        this.props.user && this.props.user != ""
          ? this.state.menu
          : this.state.LoginMenu
      }`,
    });
    var checkUrl = this.props.match.url;
    this.setState({ activeButton: checkUrl });
  }

  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // }

  handalActiveButton(type) {
    this.setState({
      activeButton: type,
    });
  }
  render() {
    const { user } = this.props;
    const mainMenu = user && user ? this.state.menu : this.state.LoginMenu;
    console.log(mainMenu, "allmenu");
    console.log(this.state.activeButton, "htll");
    const classes = classNames(
      "theme-main-menu",
      "full-width",
      "color-white",
      this.state.stickyTop && "fixed"
    );
    return (
      <nav className="main-navbar navbar navbar-expand-lg mb-0">
        <div className="container-fluid px-lg-0 px-3">
          <Link className="navbar-brand ps-lg-4" title="Logo" to="/">
            <img
              src={require("../../../assets/images/logo.png")}
              // src="images/logo.png"
              alt="wizirx2"
            />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => {
              console.log("hello");
              this.setState({
                showMenu: !this.state.showMenu,
              });
            }}
          >
            <i className="bi bi-list"></i>
          </button>
          <div
            className={
              this.state.showMenu == true
                ? "navbar-collapse"
                : "collapse navbar-collapse"
            }
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto">
              {mainMenu.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={
                      this.state.activeButton.includes(`${item.active}`)
                        ? "signup nav-item"
                        : "nav-item"
                    }
                    // onClick={() => {
                    //   this.handalActiveButton(`${item.active}`);
                    // }}
                  >
                    <Link
                      className="nav-link active"
                      title={item.title}
                      to={item.path}
                    >
                      {item.Name}
                    </Link>
                  </li>
                );
              })}
              {/* <li
                className={
                  this.state.activeButton == "exchange"
                    ? "signup nav-item"
                    : "nav-item"
                }
                onClick={() => {
                  this.handalActiveButton("exchange");
                }}
              >
                <Link
                  className="nav-link active"
                  title="Exchange"
                  to="/exchange"
                >
                  Exchange
                </Link>
              </li>
              <li
                className={
                  this.state.activeButton == "P2P"
                    ? "signup nav-item"
                    : "nav-item"
                }
                onClick={() => {
                  this.handalActiveButton("P2P");
                }}
              >
                <Link className="nav-link" title="P2P" to="#">
                  P2P
                </Link>
              </li>
              <li
                className={
                  this.state.activeButton == "Dashboard"
                    ? "signup nav-item"
                    : "nav-item"
                }
                onClick={() => {
                  this.handalActiveButton("Dashboard");
                }}
              >
                {user ? (
                  <Link className="nav-link" title="Log in" to="/user-settings">
                    Dashboard
                  </Link>
                ) : (
                  <Link className="nav-link" title="Log in" to="/login">
                    Log in
                  </Link>
                )}
              </li>
              <li
                // className="nav-item "
                className={
                  this.state.activeButton == "logout"
                    ? "signup nav-item"
                    : "nav-item"
                }
                onClick={() =>
                  // this.setState({
                  //   activeButton: "logout",
                  // })
                  this.handalActiveButton("logout")
                }
              >
                {user ? (
                  <Link className="nav-link" title="Log out" to="/logout">
                    Logout
                  </Link>
                ) : (
                  <Link
                    className="nav-link"
                    title="Sign Up"
                    to="/signup"
                    // onClick={
                    //   () =>
                    //     this.setState({
                    //       activeButton: "signup",
                    //     })
                    //   // this.hendalActiveButton("signup")
                    // }
                  >
                    Sign Up
                  </Link>
                )}
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

DefaultNavbar.propTypes = {
  /**
   * The layout type where the DefaultNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

DefaultNavbar.defaultProps = {
  stickyTop: true,
};

//export default DefaultNavbar;

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const actionCreators = {};
export default connect(mapState, actionCreators)(DefaultNavbar);
