import { userConstants } from '../../_constants';

const initialState = {
  items: [],
  loading_o: false,
  fetched_o: false,
  error_o: null
};

export default function open_orders_list(state = initialState, action) {
  switch (action.type) {
    case userConstants.OPEN_ORDERS_REQUEST:
      return {
        ...state,
        loading_o: true,
        error_o: null
      };
    case userConstants.OPEN_ORDERS_SUCCESS:
      return {
        ...state,
        loading_o: false,
        fetched_o: true,
        items: action.items
      };
    case userConstants.OPEN_ORDERS_FAILURE:
      return {
        ...state,
        loading_o: false,
        error_o: action.error,
        items: []
      };
    default:
      return state
  }
}
