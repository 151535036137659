export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'USERS_FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'USERS_FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'USERS_FORGOT_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',


    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    PROFILE_REQUEST: 'USERS_PROFILE_REQUEST',
    PROFILE_SUCCESS: 'USERS_PROFILE_SUCCESS',
    PROFILE_FAILURE: 'USERS_PROFILE_FAILURE',

    PROFILE_UPDATE_REQUEST: 'USERS_PROFILE_UPDATE_REQUEST',
    PROFILE_UPDATE_SUCCESS: 'USERS_PROFILE_UPDATE_SUCCESS',
    PROFILE_UPDATE_FAILURE: 'USERS_PROFILE_UPDATE_FAILURE',

    PROFILE_EDIT_CHANGE_MODE: 'USERS_PROFILE_EDIT_CHANGE_MODE',

    CHANGE_PASSWORD_REQUEST: 'USERS_PROFILE_CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'USERS_PROFILE_CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'USERS_PROFILE_CHANGE_PASSWORD_FAILURE',

    UPDATE_KYC_REQUEST: 'USERS_UPDATE_KYC_REQUEST',
    UPDATE_KYC_SUCCESS: 'USERS_UPDATE_KYC_SUCCESS',
    UPDATE_KYC_FAILURE: 'USERS_UPDATE_KYC_FAILURE',

    
    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    COMPANY_REQUEST: 'ONBOARD_COMPANY_REQUEST',
    COMPANY_SUCCESS: 'ONBOARD_COMPANY_SUCCESS',
    COMPANY_FAILURE: 'ONBOARD_COMPANY_FAILURE',

    LINKEDIN_REQUEST: 'ONBOARD_LINKEDIN_REQUEST',
    LINKEDIN_SUCCESS: 'ONBOARD_LINKEDIN_SUCCESS',
    LINKEDIN_FAILURE: 'ONBOARD_LINKEDIN_FAILURE',

    LINKEDIN_PAGE_REQUEST: 'ONBOARD_LINKEDIN_PAGE_REQUEST',
    LINKEDIN_PAGE_SUCCESS: 'ONBOARD_LINKEDIN_PAGE_SUCCESS',
    LINKEDIN_PAGE_FAILURE: 'ONBOARD_LINKEDIN_PAGE_FAILURE',

    TWITTER_REQUEST: 'ONBOARD_TWITTER_REQUEST',
    TWITTER_SUCCESS: 'ONBOARD_TWITTER_SUCCESS',
    TWITTER_FAILURE: 'ONBOARD_TWITTER_FAILURE',

    INSTAGRAM_REQUEST: 'ONBOARD_INSTAGRAM_REQUEST',
    INSTAGRAM_SUCCESS: 'ONBOARD_INSTAGRAM_SUCCESS',
    INSTAGRAM_FAILURE: 'ONBOARD_INSTAGRAM_FAILURE',

    FACEBOOK_REQUEST: 'ONBOARD_FACEBOOK_REQUEST',
    FACEBOOK_SUCCESS: 'ONBOARD_FACEBOOK_SUCCESS',
    FACEBOOK_FAILURE: 'ONBOARD_FACEBOOK_FAILURE',

    GET_MEMBERSHIP_REQUEST: 'USERS_MEMBERSHIP_REQUEST',
    GET_MEMBERSHIP_SUCCESS: 'USERS_MEMBERSHIP_SUCCESS',
    GET_MEMBERSHIP_FAILURE: 'USERS_MEMBERSHIP_FAILURE',
    MEMBERSHIP_USAGE_UPDATE: 'USERS_MEMBERSHIP_USAGE_UPDATE',
    ENTERPRISE_JOBS_USAGE_UPDATE: 'USERS_ENTERPRISE_JOBS_USAGE_UPDATE',

    BRAND_COLOR_UPDATE_REQUEST: 'USERS_BRAND_COLOR_UPDATE_REQUEST',
    BRAND_COLOR_UPDATE_SUCCESS: 'USERS_BRAND_COLOR_UPDATE_SUCCESS',
    BRAND_COLOR_UPDATE_FAILURE: 'USERS_BRAND_COLOR_UPDATE_FAILURE',

    BRAND_LOGO_UPLOAD_REQUEST: 'USERS_BRAND_LOGO_UPLOAD_REQUEST',
    BRAND_LOGO_UPLOAD_SUCCESS: 'USERS_BRAND_LOGO_UPLOAD_SUCCESS',
    BRAND_LOGO_UPLOAD_FAILURE: 'USERS_BRAND_LOGO_UPLOAD_FAILURE',

    BRAND_LOGO_RESET: 'USERS_BRAND_LOGO_RESET',

    BRAND_LOGO_REQUEST: 'USERS_BRAND_LOGO_REQUEST',
    BRAND_LOGO_SUCCESS: 'USERS_BRAND_LOGO_SUCCESS',
    BRAND_LOGO_FAILURE: 'USERS_BRAND_LOGO_FAILURE',

    BRAND_LOGO_DELETE_REQUEST: 'USERS_BRAND_LOGO_DELETE_REQUEST',
    BRAND_LOGO_DELETE_SUCCESS: 'USERS_BRAND_LOGO_DELETE_SUCCESS',
    BRAND_LOGO_DELETE_FAILURE: 'USERS_BRAND_LOGO_DELETE_FAILURE',

    BRAND_IMAGE_UPLOAD_REQUEST: 'USERS_BRAND_IMAGE_UPLOAD_REQUEST',
    BRAND_IMAGE_UPLOAD_SUCCESS: 'USERS_BRAND_IMAGE_UPLOAD_SUCCESS',
    BRAND_IMAGE_UPLOAD_FAILURE: 'USERS_BRAND_IMAGE_UPLOAD_FAILURE',
    BRAND_IMAGE_RESET: 'USERS_BRAND_IMAGE_RESET',

    BRAND_IMAGE_REQUEST: 'USERS_BRAND_IMAGE_REQUEST',
    BRAND_IMAGE_SUCCESS: 'USERS_BRAND_IMAGE_SUCCESS',
    BRAND_IMAGE_FAILURE: 'USERS_BRAND_IMAGE_FAILURE',

    BRAND_IMAGE_DELETE_REQUEST: 'USERS_BRAND_IMAGE_DELETE_REQUEST',
    BRAND_IMAGE_DELETE_SUCCESS: 'USERS_BRAND_IMAGE_DELETE_SUCCESS',
    BRAND_IMAGE_DELETE_FAILURE: 'USERS_BRAND_IMAGE_DELETE_FAILURE',

    BRAND_FONT_UPLOAD_REQUEST: 'USERS_BRAND_FONT_UPLOAD_REQUEST',
    BRAND_FONT_UPLOAD_SUCCESS: 'USERS_BRAND_FONT_UPLOAD_SUCCESS',
    BRAND_FONT_UPLOAD_FAILURE: 'USERS_BRAND_FONT_UPLOAD_FAILURE',
    BRAND_FONT_RESET: 'USERS_BRAND_FONT_RESET',

    BRAND_FONT_LIST_REQUEST: 'USERS_BRAND_FONT_LIST_REQUEST',
    BRAND_FONT_LIST_SUCCESS: 'USERS_BRAND_FONT_LIST_SUCCESS',
    BRAND_FONT_LIST_FAILURE: 'USERS_BRAND_FONT_LIST_FAILURE',

    BRAND_FONT_DELETE_REQUEST: 'USERS_BRAND_FONT_DELETE_REQUEST',
    BRAND_FONT_DELETE_SUCCESS: 'USERS_BRAND_FONT_DELETE_SUCCESS',
    BRAND_FONT_DELETE_FAILURE: 'USERS_BRAND_FONT_DELETE_FAILURE',

    BRAND_TEMPLATE_REQUEST: 'USERS_BRAND_TEMPLATE_REQUEST',
    BRAND_TEMPLATE_SUCCESS: 'USERS_BRAND_TEMPLATE_SUCCESS',
    BRAND_TEMPLATE_FAILURE: 'USERS_BRAND_TEMPLATE_FAILURE',
    
    EMAIL_VERIFICATION_REQUEST: 'USERS_EMAIL_VERIFICATION_REQUEST',
    EMAIL_VERIFICATION_SUCCESS: 'USERS_EMAIL_VERIFICATION_SUCCESS',
    EMAIL_VERIFICATION_FAILURE: 'USERS_EMAIL_VERIFICATION_FAILURE',

    RESEND_EMAIL_VERIFICATION_REQUEST: 'USERS_RESEND_EMAIL_VERIFICATION_REQUEST',
    RESEND_EMAIL_VERIFICATION_SUCCESS: 'USERS_RESEND_EMAIL_VERIFICATION_SUCCESS',
    RESEND_EMAIL_VERIFICATION_FAILURE: 'USERS_RESEND_EMAIL_VERIFICATION_FAILURE',

    VIEW_HOW_WORKS_VIDEO_REQUEST: 'USERS_VIEW_HOW_WORKS_VIDEO_REQUEST',
    VIEW_HOW_WORKS_VIDEO_SUCCESS: 'USERS_VIEW_HOW_WORKS_VIDEO_SUCCESS',
    VIEW_HOW_WORKS_VIDEO_FAILURE: 'USERS_VIEW_HOW_WORKS_VIDEO_FAILURE',

    BUY_CRYPTO_REQUEST: 'USER_BUY_CRYPTO_REQUEST',
    BUY_CRYPTO_SUCCESS: 'USER_BUY_CRYPTO_SUCCESS',
    BUY_CRYPTO_FAILURE: 'USER_BUY_CRYPTO_FAILURE',

    SELL_CRYPTO_REQUEST: 'USER_SELL_CRYPTO_REQUEST',
    SELL_CRYPTO_SUCCESS: 'USER_SELL_CRYPTO_SUCCESS',
    SELL_CRYPTO_FAILURE: 'USER_SELL_CRYPTO_FAILURE',

    COMPLETED_ORDERS_REQUEST: 'USER_COMPLETED_ORDERS_REQUEST',
    COMPLETED_ORDERS_SUCCESS: 'USER_COMPLETED_ORDERS_SUCCESS',
    COMPLETED_ORDERS_FAILURE: 'USER_COMPLETED_ORDERS_FAILURE',

    OPEN_ORDERS_REQUEST: 'USER_OPEN_ORDERS_REQUEST',
    OPEN_ORDERS_SUCCESS: 'USER_OPEN_ORDERS_SUCCESS',
    OPEN_ORDERS_FAILURE: 'USER_OPEN_ORDERS_FAILURE',

    COMPLETED_ORDERS_ALL_REQUEST: 'USER_COMPLETED_ORDERS_ALL_REQUEST',
    COMPLETED_ORDERS_ALL_SUCCESS: 'USER_COMPLETED_ORDERS_ALL_SUCCESS',
    COMPLETED_ORDERS_ALL_FAILURE: 'USER_COMPLETED_ORDERS_ALL_FAILURE',

    OPEN_ORDERS_ALL_REQUEST: 'USER_OPEN_ORDERS_ALL_REQUEST',
    OPEN_ORDERS_ALL_SUCCESS: 'USER_OPEN_ORDERS_ALL_SUCCESS',
    OPEN_ORDERS_ALL_FAILURE: 'USER_OPEN_ORDERS_ALL_FAILURE',

    BUY_ORDER_UPDATE_REQUEST: 'BUY_ORDER_UPDATE_REQUEST',
    BUY_ORDER_UPDATE_SUCCESS: 'BUY_ORDER_UPDATE_SUCCESS',
    BUY_ORDER_UPDATE_FAILURE: 'BUY_ORDER_UPDATE_FAILURE',

    USER_CURRENCY_REQUEST: 'USER_CURRENCY_REQUEST',
    USER_CURRENCY_SUCCESS: 'USER_CURRENCY_SUCCESS',
    USER_CURRENCY_FAILURE: 'USER_CURRENCY_FAILURE',

    USER_CURRENCY_UPDATE_REQUEST: 'USER_CURRENCY_UPDATE_REQUEST',
    USER_CURRENCY_UPDATE_SUCCESS: 'USER_CURRENCY_UPDATE_SUCCESS',
    USER_CURRENCY_UPDATE_FAILURE: 'USER_CURRENCY_UPDATE_FAILURE',
    


};
