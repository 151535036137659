import React from "react";
import { connect } from "react-redux";
import { commonActions, userActions } from "../_actions";
import { Link } from "react-router-dom";
// import { Form } from "reactstrap";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import AOS from "aos";
import DismissibleAlert from "components/common/DismissibleAlert";
import { toast } from "react-toastify";
// import Paypal from "components/Paypal/Paypal";

const options = [
  {
    label: "LIMIT",
    value: "No",
  },
  {
    label: "STOP LIMIT",
    value: "Yes",
  },
];

class Index extends React.Component {
  constructor(props) {
    super(props);
    console.log("props", props);

    var code = this.props.match.params.code;

    //this.setState({ code });
    this.state = {
      form: {
        email: "",
      },
      // allPaymentType: [
      //   "Paypal",
      //   "Amazon pay",
      //   "Skrill",
      //   "Stripe",
      //   "2Checkout",
      //   "Authorize.Net",
      //   "Payza",
      //   "SecurionPay",
      // ],
      selectPaymentType: "",
      active_tab: "INR",
      active_action_tab: "buy",
      active_order_tab: "open",
      open: false,
      submitted: false.valueOf,
      sell_at_price: "",
      sell_amount: "",
      sell_total: "",
      sell_stop_limit: false,
      sell_stop_price: null,
      sell_stop_limit_str: "No",
      buy_at_price: "",
      buy_amount: "",
      buy_total: "",
      buy_stop_limit: false,
      buy_stop_price: null,
      buy_stop_limit_str: "No",
      code: code,
      active_orderbook_tab: "market",
      modal: false,
    };
    this.handleClickTab = this.handleClickTab.bind(this);
    this.handleSellSubmit = this.handleSellSubmit.bind(this);
    this.handleBuySubmit = this.handleBuySubmit.bind(this);
    this.handleClickActionTab = this.handleClickActionTab.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStopLimitSell = this.handleStopLimitSell.bind(this);
    this.handleStopLimitBuy = this.handleStopLimitBuy.bind(this);
    this.handleClickOrderTab = this.handleClickOrderTab.bind(this);
    this.handleClickOrderBookTab = this.handleClickOrderBookTab.bind(this);
    this.modal_box = this.modal_box.bind(this);
  }

  handleClickOrderBookTab(active_orderbook_tab) {
    this.setState({
      active_orderbook_tab: active_orderbook_tab,
    });
  }

  handleClickActionTab(active_action_tab) {
    this.setState({
      active_action_tab: active_action_tab,
    });
  }

  handleClickOrderTab(active_order_tab) {
    this.setState({
      active_order_tab: active_order_tab,
    });
  }

  handleClickTab(tab_name) {
    this.setState({
      active_tab: tab_name,
    });
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    AOS.init({
      duration: 2000,
    });
    const {
      fetched,
      getData,
      getPairData,
      fetched_pd,
      getOrderBook,
      fetched_orderbook,
      pairList,
    } = this.props;
    if (fetched === false) {
      getData();
    }

    if (fetched_pd === false) {
      var code = this.props.match.params.code;
      getPairData(code);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      pair_data,
      fetched_c,
      fetched_o,
      fetched_orderbook,
      orderbooklist,
      pairList,
    } = nextProps;

    if (this.props.pair_data !== pair_data) {
      if (fetched_c === false) {
        let postData = {
          asset_id: Number(pair_data.asset_id),
          pair_id: Number(pair_data.pair_id),
        };
        this.props.completedOrders(postData);
      }

      if (fetched_o === false) {
        let postData = {
          asset_id: Number(pair_data.asset_id),
          pair_id: Number(pair_data.pair_id),
        };
        this.props.openOrders(postData);
      }
    }

    if (this.props.orderbooklist !== orderbooklist) {
      if (fetched_orderbook === false) {
        this.props.getOrderBook(pair_data.asset_id, pair_data.pair_id);
      }
    }

    var code = this.props.match.params.code;
    if (code == undefined) {
      console.log("pairList", pairList);
      let __default_code = "";
      for (let i = 0; i < pairList.length; i++) {
        if (pairList[i].assest_data != null) {
          for (let j = 0; j < pairList[i].assest_data.length; j++) {
            if (pairList[i].assest_data[j].default_selected == 1)
              __default_code = pairList[i].assest_data[j].asset_code;
          }
        }
      }

      if (__default_code != "") {
        this.props.history.push("/exchange/" + __default_code);
      }
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSellSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { pair_data } = this.props;
    const {
      sell_at_price,
      sell_amount,
      sell_total,
      sell_stop_limit,
      sell_stop_price,
    } = this.state;
    if (sell_at_price != "" && sell_amount != "" && sell_total != "") {
      let postData = {
        asset_id: Number(pair_data.asset_id),
        pair_id: Number(pair_data.pair_id),
        at_price: Number(sell_at_price),
        amount: Number(sell_amount),
        total: Number(sell_total),
        stop_limit: sell_stop_limit,
        stop_price: Number(sell_stop_price),
      };

      this.props.sellCrypto(postData);
    } else {
      toast.error("Please enter all data before submit");
    }
  }

  handleBuySubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { pair_data } = this.props;
    const {
      buy_at_price,
      buy_amount,
      buy_total,
      buy_stop_limit,
      buy_stop_price,
    } = this.state;
    if (buy_at_price != "" && buy_amount != "" && buy_total != "") {
      var postData = {
        asset_id: Number(pair_data.asset_id),
        pair_id: Number(pair_data.pair_id),
        at_price: Number(buy_at_price),
        amount: Number(buy_amount),
        total: Number(buy_total),
        stop_limit: buy_stop_limit,
        stop_price: Number(buy_stop_price),
      };

      this.props.buyCrypto(postData);
    } else {
      toast.error("Please enter all data before submit");
    }
  }

  handleStopLimitSell(e) {
    let valuetoset = false;
    let value = e.target.value;
    if (value == "Yes") valuetoset = true;
    else valuetoset = false;
    this.setState({ sell_stop_limit: valuetoset, sell_stop_limit_str: value });
  }

  handleStopLimitBuy(e) {
    let valuetoset = false;
    let value = e.target.value;
    if (value == "Yes") valuetoset = true;
    else valuetoset = false;
    this.setState({ buy_stop_limit: valuetoset, buy_stop_limit_str: value });
  }
  modal_box() {
    console.log("hello");
    this.setState({
      modal: !this.state.modal,
    });
  }
  render() {
    const {
      pairList,
      loading,
      user,
      pair_data,
      loading_c,
      completed_orders_list,
      open_orders_list,
      orderbooklist,
      loading_orderbook,
    } = this.props;
    const {
      sell_at_price,
      sell_amount,
      sell_total,
      sell_stop_limit,
      sell_stop_price,
      sell_stop_limit_str,
      buy_at_price,
      buy_amount,
      buy_total,
      buy_stop_limit,
      buy_stop_price,
      buy_stop_limit_str,
    } = this.state;

    return (
      <>
        <section className="inner-page-section exchange-part">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-sm-2"
                // className="w-25"
              >
                <div className="currency-col">
                  <nav>
                    {loading && "Loading..."}
                    <div
                      className={"nav nav-tabs "}
                      id="nav-tab"
                      role="tablist"
                    >
                      {pairList &&
                        pairList.map((pair, i) => (
                          <span
                            key={pair.asset_code}
                            onClick={() => this.handleClickTab(pair.asset_code)}
                            className={
                              "nav-item nav-link " +
                              (this.state.active_tab == pair.asset_code
                                ? "active"
                                : "")
                            }
                            id="nav-inr-tab"
                            data-toggle="tab"
                            href="#nav-inr"
                            role="tab"
                            aria-controls="nav-inr"
                            aria-selected="true"
                          >
                            {pair.asset_code}
                          </span>
                        ))}
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    {loading && "Loading..."}
                    {pairList.map((pair) => (
                      <div
                        key={pair.asset_code}
                        className={
                          "tab-pane fade " +
                          (this.state.active_tab == pair.asset_code
                            ? "show active"
                            : "hide")
                        }
                        id={pair.asset_code}
                        role="tabpanel"
                        aria-labelledby="nav-inr-tab"
                      >
                        <div className="currency-search">
                          <div className="input-area">
                            <i className="fa fa-search mdi-pre"></i>
                            <input
                              className="currency-search-input"
                              placeholder="Search"
                              type="text"
                            />
                          </div>
                          <div className="list-sort">
                            <div className="list-sort-item active">
                              <span className="not-selectable">Pair</span>
                              <i className="fa marker fa-arrow-up"></i>
                            </div>
                            <div className="list-sort-item">
                              <span className="not-selectable">Vol</span>
                              <i className="fa marker fa-arrow-down"></i>
                            </div>
                            <div className="list-sort-item">
                              <span className="not-selectable">Change</span>
                              <i className="fa marker fa-arrow-up"></i>
                            </div>
                          </div>
                        </div>
                        <div className="currency-content">
                          {pair.assest_data !== null &&
                            pair.assest_data.map((pair_item) => (
                              <Link
                                key={pair_item.asset_code}
                                className="ticker-item"
                                to={"/exchange/" + pair_item.asset_code}
                              >
                                <div className="currency-logo">
                                  <img
                                    src={require("assets/images/icon/84.png")}
                                    alt="1INCH"
                                    className="sc-fjdhpX gcvOpy"
                                  />
                                </div>
                                <div className="market">
                                  <div className="market-name">
                                    <span className="market-name-text">
                                      {pair_item.asset_code}
                                    </span>
                                  </div>
                                  <div className="market-change">
                                    <span
                                      color="#00C853"
                                      className="sc-bwzfXH cFmqCk"
                                    >
                                      ▲ 14.11%
                                    </span>
                                  </div>
                                </div>
                                <div className="price">
                                  <div className="price-box ">
                                    <span className="price-text ticker-price">
                                      ₹ 217.510
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            ))}

                          {pair.assest_data == null ? (
                            <div className="ticker-item">No record found</div>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div
                className="col-sm-7 p-0"
                // className="w-50 p-0"
              >
                <div className="mid-graph-part mt-3">
                  <div className="mid-img">
                    <img
                      src={require("assets/images/mid-graph.jpg")}
                      width="100%"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="order-part fhTEcc">
                      <nav>
                        <div
                          className="nav nav-tabs"
                          id="nav-tab"
                          role="tablist"
                        >
                          <span
                            onClick={() =>
                              this.handleClickOrderBookTab("market")
                            }
                            className={
                              "nav-item nav-link " +
                              (this.state.active_orderbook_tab == "market"
                                ? "active"
                                : "")
                            }
                            id="orderbookmarket-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="orderbookmarket"
                            aria-selected="true"
                          >
                            MARKET DEPTH
                          </span>
                          <span
                            onClick={() =>
                              this.handleClickOrderBookTab("valume")
                            }
                            className={
                              "nav-item nav-link " +
                              (this.state.active_orderbook_tab == "valume"
                                ? "active"
                                : "")
                            }
                            id="orderbookvalume-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="orderbookvalume"
                            aria-selected="false"
                          >
                            ORDER VOLUME
                          </span>
                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        <div
                          className={
                            "tab-pane fade " +
                            (this.state.active_orderbook_tab == "market"
                              ? "show active"
                              : "hide")
                          }
                          id="orderbookmarket"
                          role="tabpanel"
                          aria-labelledby="orderbookmarket-tab"
                        >
                          <div className="order-table-part">
                            {loading_c && "Loading..."}

                            <table
                              width="100%"
                              border="0"
                              cellSpacing="0"
                              cellPadding="0"
                              className="table"
                            >
                              <tbody>
                                <tr>
                                  <td>VOLUME</td>
                                  <td>BUY PRICE</td>
                                  <td>SELL PRICE</td>
                                  <td>VOLUME</td>
                                </tr>

                                {orderbooklist &&
                                  orderbooklist.map((order_book, i) => (
                                    <tr
                                    // key={i}
                                    >
                                      <td>{order_book.volume_buy}</td>
                                      <td>{order_book.buy_price}</td>
                                      <td>{order_book.sell_price}</td>
                                      <td>{order_book.volume_sell}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className={
                            "tab-pane fade " +
                            (this.state.active_orderbook_tab == "valume"
                              ? "show active"
                              : "hide")
                          }
                          id="orderbookvolume"
                          role="tabpanel"
                          aria-labelledby="orderbookvalume-tab"
                        >
                          <div className="order-table-part">
                            <table
                              width="100%"
                              border="0"
                              cellSpacing="0"
                              cellPadding="0"
                              className="table"
                            >
                              <tbody>
                                <tr>
                                  <td>VOLUME</td>
                                  <td>BUY PRICE</td>
                                  <td>SELL PRICE</td>
                                  <td>VOLUME</td>
                                </tr>

                                {orderbooklist &&
                                  orderbooklist.map((order_book) => (
                                    <tr>
                                      <td>{order_book.volume_buy}</td>
                                      <td>{order_book.buy_price}</td>
                                      <td>{order_book.sell_price}</td>
                                      <td>{order_book.volume_sell}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="order-part fhTEcc">
                      <nav>
                        <div
                          className="nav nav-tabs"
                          id="nav-tab"
                          role="tablist"
                        >
                          <span
                            className="nav-item nav-link active"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="orderbookmarket"
                            aria-selected="true"
                          >
                            TRADE HISTORY
                          </span>
                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        <div
                          className="tab-pane fade show active"
                          role="tabpanel"
                          aria-labelledby="orderbookmarket-tab"
                        >
                          <div className="order-table-part">
                            {loading_c && "Loading..."}

                            <table
                              width="100%"
                              border="0"
                              cellSpacing="0"
                              cellPadding="0"
                              className="table"
                            >
                              <tbody>
                                <tr>
                                  <td>PRICE</td>
                                  <td>VOLUME</td>
                                  <td>TIME</td>
                                </tr>

                                {orderbooklist &&
                                  orderbooklist.map((order_book) => (
                                    <tr>
                                      <td>{order_book.volume_buy}</td>
                                      <td>{order_book.buy_price}</td>
                                      <td>{order_book.sell_price}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-3"
                // className="w-25"
              >
                {user ? (
                  <div className="order-part fhTEcc">
                    <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <span
                          onClick={() => this.handleClickOrderTab("open")}
                          className={
                            "nav-item nav-link " +
                            (this.state.active_order_tab == "open"
                              ? "active"
                              : "")
                          }
                          id="open-order-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="open-order"
                          aria-selected="true"
                        >
                          Open Orders
                        </span>
                        <span
                          onClick={() => this.handleClickOrderTab("completed")}
                          className={
                            "nav-item nav-link " +
                            (this.state.active_order_tab == "completed"
                              ? "active"
                              : "")
                          }
                          id="completed-orders-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="completed-orders"
                          aria-selected="false"
                        >
                          Completed Orders
                        </span>
                      </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className={
                          "tab-pane fade " +
                          (this.state.active_order_tab == "open"
                            ? "show active"
                            : "hide")
                        }
                        id="open-order"
                        role="tabpanel"
                        aria-labelledby="open-order-tab"
                      >
                        <div className="toggle-part">
                          <label className="switch-wrap">
                            <input type="checkbox" />
                            <div className="switch"></div>
                          </label>
                          <div className="btc-order-text">BTC-INR Orders</div>
                        </div>
                        <div className="order-table-part">
                          {loading_c && "Loading..."}

                          <table
                            width="100%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            className="table"
                          >
                            <tbody>
                              <tr>
                                <td>Pair</td>
                                <td>Amount</td>
                                <td>Price</td>
                                <td>Total</td>
                              </tr>

                              {open_orders_list &&
                                open_orders_list.map((o_order) => (
                                  <tr key={o_order.id}>
                                    <td>{o_order.pair_code}</td>
                                    <td>{o_order.amount}</td>
                                    <td>{o_order.at_price}</td>
                                    <td>{o_order.total}</td>
                                  </tr>
                                ))}

                              {open_orders_list != null ? (
                                <tr>
                                  <td
                                    colSpan={4}
                                    style={{ textAlign: "center" }}
                                  >
                                    No records found
                                  </td>
                                </tr>
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        className={
                          "tab-pane fade " +
                          (this.state.active_order_tab == "completed"
                            ? "show active"
                            : "hide")
                        }
                        id="completed-orders"
                        role="tabpanel"
                        aria-labelledby="completed-orders-tab"
                      >
                        <div className="toggle-part">
                          <label className="switch-wrap">
                            <input type="checkbox" />
                            <div className="switch"></div>
                          </label>
                          <div className="btc-order-text">BTC-INR Orders</div>
                        </div>
                        <div className="order-table-part">
                          <table
                            width="100%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            className="table"
                          >
                            <tbody>
                              <tr>
                                <td>Pair</td>
                                <td>Amount</td>
                                <td>Price</td>
                                <td>Total</td>
                              </tr>

                              {completed_orders_list &&
                                completed_orders_list.map((c_order) => (
                                  <tr key={c_order.pair_code}>
                                    <td>{c_order.pair_code}</td>
                                    <td>{c_order.amount}</td>
                                    <td>{c_order.at_price}</td>
                                    <td>{c_order.total}</td>
                                  </tr>
                                ))}

                              {completed_orders_list != null ? (
                                <tr>
                                  <td
                                    colSpan={4}
                                    style={{ textAlign: "center" }}
                                  >
                                    No records found
                                  </td>
                                </tr>
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="order-part fhTEcc">
                    <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          id="open-order-tab"
                          data-toggle="tab"
                          href="#open-order"
                          role="tab"
                          aria-controls="open-order"
                          aria-selected="true"
                        >
                          Open Orders
                        </a>
                        <a
                          className="nav-item nav-link"
                          id="completed-orders-tab"
                          data-toggle="tab"
                          href="#completed-orders"
                          role="tab"
                          aria-controls="completed-orders"
                          aria-selected="false"
                        >
                          Completed Orders
                        </a>
                      </div>
                    </nav>
                    <div
                      className="tab-content"
                      id="nav-tabContent"
                      style={{ padding: 30 }}
                    >
                      <Link className="btn btn-primary btn-block" to="/login">
                        Login <i className="flaticon-right-thin"></i>
                      </Link>

                      <span style={{ display: "block", textAlign: "center" }}>
                        OR
                      </span>

                      <Link
                        className="btn btn-secondary btn-block"
                        to="/signup"
                      >
                        Signup <i className="flaticon-right-thin"></i>
                      </Link>
                    </div>
                  </div>
                )}

                <div className="order-part fhTEcc">
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <span
                        onClick={() => this.handleClickActionTab("buy")}
                        className={
                          "nav-item nav-link " +
                          (this.state.active_action_tab == "buy"
                            ? "active"
                            : "")
                        }
                        id="buy-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="buy"
                        aria-selected="true"
                      >
                        Buy
                      </span>
                      <span
                        onClick={() => this.handleClickActionTab("sell")}
                        className={
                          "nav-item nav-link " +
                          (this.state.active_action_tab == "sell"
                            ? "active"
                            : "sell")
                        }
                        id="sell-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="sell"
                        aria-selected="false"
                      >
                        Sell
                      </span>
                    </div>
                  </nav>
                  {/* <div
                    className="tab-content"
                    id="nav-tabContent"
                    style={{ padding: 30 }}
                  >
                    <button
                      className="btn btn-primary btn-block"
                      // onClick={() => {}}
                      onClick={this.modal_box}
                    >
                      Pay <i className="flaticon-right-thin"></i>
                    </button>
                  </div> */}
                  {/* <div>
                   

                    <select name="cars" id="cars">
                      <option
                        onClick={() => {
                          this.setState({ selectPaymentType: "" });
                        }}
                        value=""
                      >
                        Select Type
                      </option>
                      {this.state.allPaymentType?.map((type) => {
                        return (
                          <option
                            onClick={() => {
                              this.setState({ selectPaymentType: type });
                            }}
                            value={type}
                          >
                            {type}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                  {/* {this.state.selectPaymentType &&
                  this.state.selectPaymentType == "Paypal" ? (
                    <Paypal />
                  ) : (
                    ""
                  )} */}
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className={
                        "tab-pane fade " +
                        (this.state.active_action_tab == "buy"
                          ? "show active"
                          : "hide")
                      }
                      id="buy"
                      role="tabpanel"
                      aria-labelledby="buy-tab"
                    >
                      <div className="sc-gwVKww iTAwTl">
                        <div>
                          <Form
                            className="sc-gPEVay iOMNyh"
                            role="form"
                            name="form"
                            onSubmit={this.handleBuySubmit}
                          >
                            {alert.message && (
                              <DismissibleAlert
                                message={alert.message}
                                theme={alert.type}
                              />
                            )}
                            <div className="sc-bdVaJa bHgTye">
                              <div
                                className="css-ap7vkc-container"
                                style={{ width: "100%" }}
                              >
                                <div>
                                  <select
                                    style={{
                                      padding: "6px",
                                      height: "41px",
                                      borderColor: "#e4e4e4",
                                      fontSize: "14px",
                                      color: "#aaa",
                                    }}
                                    className="form-control"
                                    name="buy_stop_limit"
                                    id="buy_stop_limit"
                                    value={buy_stop_limit_str}
                                    onChange={this.handleStopLimitBuy}
                                  >
                                    {options.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {buy_stop_limit === true ? (
                              <div className="sc-bdVaJa sc-dfVpRl dDBViW">
                                <div className="sc-bdVaJa sc-iyvyFf bHMydW">
                                  <div className="sc-bdVaJa sc-jtRfpW fQRysy">
                                    <div className="sc-bdVaJa sc-dqBHgY gCVUaG">
                                      <span className="sc-bwzfXH hjQJfp">
                                        STOP PRICE
                                      </span>
                                      <span className="sc-bwzfXH hSygGi">
                                        inr
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      autoCapitalize="off"
                                      autoComplete="off"
                                      spellCheck="false"
                                      autoCorrect="off"
                                      inputMode="decimal"
                                      className="sc-kTUwUJ cKOnhg"
                                      name="buy_stop_price"
                                      id="buy_stop_price"
                                      value={buy_stop_price}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            <div className="sc-bdVaJa sc-dfVpRl dDBViW">
                              <div className="sc-bdVaJa sc-iyvyFf bHMydW">
                                <div className="sc-bdVaJa sc-jtRfpW fQRysy">
                                  <div className="sc-bdVaJa sc-dqBHgY gCVUaG">
                                    <span className="sc-bwzfXH hjQJfp">
                                      AT PRICE
                                    </span>
                                    <span className="sc-bwzfXH hSygGi">
                                      inr
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    spellCheck="false"
                                    autoCorrect="off"
                                    inputMode="decimal"
                                    className="sc-kTUwUJ cKOnhg"
                                    name="buy_at_price"
                                    id="buy_at_price"
                                    value={buy_at_price}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div>
                                <span
                                  className="cur-ptr sc-bwzfXH iMHVtk"
                                  color="#55bd6c"
                                >
                                  Lowest Price
                                </span>
                              </div>
                            </div>
                            <div className="sc-bdVaJa sc-dfVpRl dDBViW">
                              <div className="sc-bdVaJa sc-iyvyFf bHMydW">
                                <div className="sc-bdVaJa sc-jtRfpW fQRysy">
                                  <div className="sc-bdVaJa sc-dqBHgY gCVUaG">
                                    <span className="sc-bwzfXH hjQJfp">
                                      Amount
                                    </span>
                                    <span className="sc-bwzfXH hSygGi">
                                      btc
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    spellCheck="false"
                                    autoCorrect="off"
                                    className="sc-kTUwUJ cKOnhg"
                                    name="buy_amount"
                                    id="buy_amount"
                                    value={buy_amount}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="sc-bdVaJa sc-dfVpRl dDBViW">
                              <div className="sc-bdVaJa sc-iyvyFf bHMydW">
                                <div className="sc-bdVaJa sc-jtRfpW fQRysy">
                                  <div className="sc-bdVaJa sc-dqBHgY gCVUaG">
                                    <span className="sc-bwzfXH hjQJfp">
                                      Total
                                    </span>
                                    <span className="sc-bwzfXH hSygGi">
                                      inr
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    spellCheck="false"
                                    autoCorrect="off"
                                    inputMode="decimal"
                                    className="sc-kTUwUJ cKOnhg"
                                    name="buy_total"
                                    id="buy_total"
                                    value={buy_total}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className="sc-bdVaJa sc-iujRgT cFmsHG">
                                  <div
                                    width="calc(100% - 125px)"
                                    className="sc-gojNiO gItioN"
                                  >
                                    <svg
                                      viewBox="0 0 43 43"
                                      width="12"
                                      height="12"
                                      fill="rgba(34, 35, 38, 0.38)"
                                      className="sc-exAgwC kvVgIg"
                                    >
                                      <path d="M31.969 27c1.688 0 3-1.313 3-3s-1.313-3-3-3-3 1.313-3 3 1.313 3 3 3zM24 31.969v-15.938h19.969v15.938h-19.969zM42 36v1.969c0 2.156-1.875 4.031-4.031 4.031h-27.938c-2.25 0-4.031-1.875-4.031-4.031v-27.938c0-2.156 1.781-4.031 4.031-4.031h27.938c2.156 0 4.031 1.875 4.031 4.031v1.969h-18c-2.25 0-4.031 1.875-4.031 4.031v15.938c0 2.156 1.781 4.031 4.031 4.031h18z"></path>
                                    </svg>
                                    <span
                                      color="rgba(34, 35, 38, 0.38)"
                                      fontWeight="600"
                                      fontSize="10px"
                                      className="sc-cQFLBn hcywEG"
                                    >
                                      ₹0
                                    </span>
                                  </div>
                                  <div className="sc-bdVaJa sc-esOvli evcoVc">
                                    <button
                                      type="button"
                                      tabIndex="0"
                                      className="sc-iuJeZd bippEu"
                                    >
                                      25%
                                    </button>
                                    <button
                                      type="button"
                                      tabIndex="0"
                                      className="sc-iuJeZd bippEu"
                                    >
                                      50%
                                    </button>
                                    <button
                                      type="button"
                                      tabIndex="0"
                                      className="sc-iuJeZd bippEu"
                                    >
                                      75%
                                    </button>
                                    <button
                                      type="button"
                                      tabIndex="0"
                                      className="sc-iuJeZd bippEu"
                                    >
                                      100%
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              height="40px"
                              type="submit"
                              className="sc-dnqmqq gttYCx"
                            >
                              <span
                                color="#ffffff"
                                className="sc-bwzfXH hdlwFq"
                              >
                                Buy
                              </span>
                            </button>
                            <span className="sc-bwzfXH hPxUoq">
                              Fee:{" "}
                              <a
                                href="/fees"
                                target="_blank"
                                color="rgba(34, 35, 38, 0.38)"
                                className="sc-fBuWsC HJBMk"
                              >
                                Maker: 0.2% | Taker: 0.2% | Incl. of all
                                applicable taxes
                              </a>
                            </span>
                          </Form>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        "tab-pane fade " +
                        (this.state.active_action_tab == "sell"
                          ? "sell-col show active"
                          : "hide")
                      }
                      id="sell"
                      role="tabpanel"
                      aria-labelledby="sell-tab"
                    >
                      <div className="sc-gwVKww iTAwTl">
                        <div>
                          <Form
                            className="sc-gPEVay iOMNyh"
                            role="form"
                            name="form"
                            onSubmit={this.handleSellSubmit}
                          >
                            <div className="sc-bdVaJa bHgTye">
                              <div
                                className="css-ap7vkc-container"
                                style={{ width: "100%" }}
                              >
                                <div>
                                  <select
                                    style={{
                                      padding: "6px",
                                      height: "41px",
                                      borderColor: "#e4e4e4",
                                      fontSize: "14px",
                                      color: "#aaa",
                                    }}
                                    className="form-control"
                                    name="sell_stop_limit"
                                    id="sell_stop_limit"
                                    value={sell_stop_limit_str}
                                    onChange={this.handleStopLimitSell}
                                  >
                                    {options.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {sell_stop_limit === true ? (
                              <div className="sc-bdVaJa sc-dfVpRl dDBViW">
                                <div className="sc-bdVaJa sc-iyvyFf bHMydW">
                                  <div className="sc-bdVaJa sc-jtRfpW fQRysy">
                                    <div className="sc-bdVaJa sc-dqBHgY gCVUaG">
                                      <span className="sc-bwzfXH hjQJfp">
                                        STOP PRICE
                                      </span>
                                      <span className="sc-bwzfXH hSygGi">
                                        inr
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      autoCapitalize="off"
                                      autoComplete="off"
                                      spellCheck="false"
                                      autoCorrect="off"
                                      inputMode="decimal"
                                      className="sc-kTUwUJ cKOnhg"
                                      name="sell_stop_price"
                                      id="sell_stop_price"
                                      value={sell_stop_price}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            <div className="sc-bdVaJa sc-dfVpRl dDBViW">
                              <div className="sc-bdVaJa sc-iyvyFf bHMydW">
                                <div className="sc-bdVaJa sc-jtRfpW fQRysy">
                                  <div className="sc-bdVaJa sc-dqBHgY gCVUaG">
                                    <span className="sc-bwzfXH hjQJfp">
                                      AT PRICE
                                    </span>
                                    <span className="sc-bwzfXH hSygGi">
                                      inr
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    spellCheck="false"
                                    autoCorrect="off"
                                    inputMode="decimal"
                                    className="sc-kTUwUJ cKOnhg"
                                    name="sell_at_price"
                                    id="sell_at_price"
                                    value={sell_at_price}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className="sc-bdVaJa sc-gxMtzJ KUJRP"
                              style={{
                                position: "relative",
                                justifyContent: "flex-end",
                                paddingTop: "16px",
                              }}
                            >
                              <span
                                className="cur-ptr sc-bwzfXH nWmdL"
                                color="#f1432f"
                                style={{ float: "right", margin: "10px 0px" }}
                              >
                                Highest Price
                              </span>
                            </div>

                            <div
                              className="sc-bdVaJa sc-dfVpRl "
                              style={{ marginTop: 0 }}
                            >
                              <div className="sc-bdVaJa sc-iyvyFf bHMydW">
                                <div className="sc-bdVaJa sc-jtRfpW fQRysy">
                                  <div className="sc-bdVaJa sc-dqBHgY gCVUaG">
                                    <span className="sc-bwzfXH hjQJfp">
                                      Amount
                                    </span>
                                    <span className="sc-bwzfXH hSygGi">
                                      btc
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    spellCheck="false"
                                    autoCorrect="off"
                                    inputMode="decimal"
                                    className="sc-kTUwUJ cKOnhg"
                                    name="sell_amount"
                                    id="sell_amount"
                                    value={sell_amount}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="sc-bdVaJa sc-iujRgT cFmsHG">
                                <div
                                  width="calc(100% - 125px)"
                                  className="sc-gojNiO gItioN"
                                >
                                  <svg
                                    viewBox="0 0 43 43"
                                    width="12"
                                    height="12"
                                    fill="rgba(34, 35, 38, 0.38)"
                                    className="sc-exAgwC kvVgIg"
                                  >
                                    <path d="M31.969 27c1.688 0 3-1.313 3-3s-1.313-3-3-3-3 1.313-3 3 1.313 3 3 3zM24 31.969v-15.938h19.969v15.938h-19.969zM42 36v1.969c0 2.156-1.875 4.031-4.031 4.031h-27.938c-2.25 0-4.031-1.875-4.031-4.031v-27.938c0-2.156 1.781-4.031 4.031-4.031h27.938c2.156 0 4.031 1.875 4.031 4.031v1.969h-18c-2.25 0-4.031 1.875-4.031 4.031v15.938c0 2.156 1.781 4.031 4.031 4.031h18z"></path>
                                  </svg>
                                  <span
                                    color="rgba(34, 35, 38, 0.38)"
                                    fontWeight="600"
                                    fontSize="10px"
                                    className="sc-cQFLBn hcywEG"
                                  >
                                    0.00000 BTC
                                  </span>
                                </div>
                                <div className="sc-bdVaJa sc-esOvli evcoVc">
                                  <button
                                    type="button"
                                    tabIndex="0"
                                    className="sc-iuJeZd gbYPuT"
                                  >
                                    25%
                                  </button>
                                  <button
                                    type="button"
                                    tabIndex="0"
                                    className="sc-iuJeZd gbYPuT"
                                  >
                                    50%
                                  </button>
                                  <button
                                    type="button"
                                    tabIndex="0"
                                    className="sc-iuJeZd gbYPuT"
                                  >
                                    75%
                                  </button>
                                  <button
                                    type="button"
                                    tabIndex="0"
                                    className="sc-iuJeZd gbYPuT"
                                  >
                                    100%
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="sc-bdVaJa sc-dfVpRl dDBViW">
                              <div className="sc-bdVaJa sc-iyvyFf bHMydW">
                                <div className="sc-bdVaJa sc-jtRfpW fQRysy">
                                  <div className="sc-bdVaJa sc-dqBHgY gCVUaG">
                                    <span className="sc-bwzfXH hjQJfp">
                                      Total
                                    </span>
                                    <span className="sc-bwzfXH hSygGi">
                                      inr
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    spellCheck="false"
                                    autoCorrect="off"
                                    inputMode="decimal"
                                    className="sc-kTUwUJ cKOnhg"
                                    name="sell_total"
                                    id="sell_total"
                                    value={sell_total}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <button
                              height="40px"
                              type="submit"
                              className="sc-dnqmqq gttYCx"
                            >
                              <span
                                color="#ffffff"
                                className="sc-bwzfXH hdlwFq"
                              >
                                Sell{" "}
                              </span>
                            </button>
                            <span className="sc-bwzfXH hPxUoq">
                              Fee:{" "}
                              <a
                                href="/fees"
                                target="_blank"
                                color="rgba(34, 35, 38, 0.38)"
                                className="sc-fBuWsC HJBMk"
                              >
                                Maker: 0.2% | Taker: 0.2% | Incl. of all
                                applicable taxes
                              </a>
                            </span>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.modal_box}
                  // className={this.props.className}
                  backdrop={this.state.backdrop}
                >
                  <ModalHeader toggle={this.modal_box}>Modal title</ModalHeader>
                  <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.modal_box}>
                      Do Something
                    </Button>{" "}
                    <Button color="secondary" onClick={this.modal_box}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  const { detail: web_config } = state.config;
  const { items: pairList, loading, fetched } = state.exchange.pair;
  const { item: pair_data, loading_pd, fetched_pd } = state.exchange.pair_data;
  const {
    items: completed_orders_list,
    loading_c,
    fetched_c,
  } = state.exchange.completed_orders_list;
  const {
    items: open_orders_list,
    loading_o,
    fetched_o,
  } = state.exchange.open_orders_list;
  const {
    items: orderbooklist,
    loading_orderbook,
    fetched_orderbook,
  } = state.exchange.orderbooklist;

  return {
    user,
    web_config,
    pairList,
    loading,
    fetched,
    pair_data,
    loading_pd,
    fetched_pd,
    completed_orders_list,
    loading_c,
    fetched_c,
    open_orders_list,
    loading_o,
    fetched_o,
    orderbooklist,
    loading_orderbook,
    fetched_orderbook,
  };
  // first;
}

const actionCreators = {
  getconfig: commonActions.getConfig,
  getData: commonActions.getPairList,
  getPairData: commonActions.pairData,
  buyCrypto: userActions.buyCrypto,
  sellCrypto: userActions.sellCrypto,
  completedOrders: userActions.completedOrders,
  openOrders: userActions.openOrders,
  getOrderBook: commonActions.getOrderBook,
};

export default connect(mapState, actionCreators)(Index);
