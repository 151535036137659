import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

class MainNavbar extends React.Component {
  constructor(props) {
    super(props);
    // var showMenu = true;

    this.state = {
      collapseClasses: "",
      collapseOpen: false,
      stickyTop: this.props.headerFixed,
      showMenu: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    let stickyTop = false;
    const { headerFixed } = this.props;
    if (headerFixed === undefined) {
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 100) {
        stickyTop = true;
      }
      this.setState({
        stickyTop: stickyTop,
      });
    }
  }

  render() {
    const { user } = this.props;
    const classes = classNames(
      "theme-main-menu",
      "full-width",
      "color-white",
      this.state.stickyTop && "fixed"
    );
    console.log(this.state.showMenu);
    return (
      <>
        <header
          className="navbar navbar-default navbar-static-top"
          role="navigation"
        >
          <div className="container-fluid">
            <div
              className={
                this.state.showMenu == true
                  ? "navbar-collapse"
                  : "collapsenavbar-collapse"
              }
              id="bs-example-navbar-collapse-1"
            >
              <div className="navbar-header">
                <button
                  // aria-expanded="false"
                  // className="navbar-toggle collapsed"
                  // data-target="#bs-example-navbar-collapse-1"
                  // data-toggle="collapse"
                  type="button"
                  onClick={() => {
                    console.log("hello");
                    // this.setState({
                    //   showMenu: !this.state.showMenu,
                    // });
                  }}
                >
                  {/* <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span> */}
                </button>
                <Link className="navbar-brand" to="/">
                  <img
                    className="logoImg"
                    src={require("assets/images/logo/logo.png")}
                  />
                </Link>
              </div>
              <ul className="nav navbar-nav nav_left">
                <li>
                  <Link to="exchange">
                    <i className="fa fa-exchange"></i>
                    <span>Trade</span>
                  </Link>
                </li>
                <li>
                  {/* <a target="" href="funds.html"> */}
                  <a target="" href="#">
                    <i className="fa fa-money"></i>
                    <span>Funds</span>
                  </a>
                </li>
                <li>
                  {/* <a target="" href="order-history.html"> */}
                  <a target="" href="#">
                    <i className="fa fa-book"></i>
                    <span>History</span>
                  </a>
                </li>
                <li>
                  {/* <a target="" href="session.html"> */}
                  <a target="" href="#">
                    <i className="fa fa-book"></i>
                    <span>
                      <span
                        className="translation_missing"
                        title="translation missing: en.header.sessions"
                      >
                        Sessions
                      </span>
                    </span>
                  </a>
                </li>
                <li>
                  <Link to="/user-kyc">
                    <i className="fa fa-book"></i>
                    <span>Kyc</span>
                  </Link>
                </li>
                <li>
                  {/* <a target="" href="currency-preference.html"> */}
                  <a target="" href="#">
                    <i className="fa fa-book"></i>
                    <span>Currency Preference</span>
                  </a>
                </li>
                <li>
                  {/* <a target="" href="payment-options.html"> */}
                  <a target="" href="#">
                    <i className="fa fa-book"></i>
                    <span>Payment Options</span>
                  </a>
                </li>
                <li>
                  {/* <a target="" href="fees.html"> */}
                  <a target="" href="#">
                    <i className="fa fa-book"></i>
                    <span>Fees</span>
                  </a>
                </li>
                <li>
                  {/* <a target="" href="coupon-rewards.html"> */}
                  <a target="" href="#">
                    <i className="fa fa-book"></i>
                    <span>Coupon Rewards</span>
                  </a>
                </li>
              </ul>
              <ul className="nav navbar-nav navbar-right">
                <li className="dropdown account-settings">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                  >
                    <span>
                      <span className="__cf_email__" data-cfemail="">
                        {user && user.email}
                      </span>
                    </span>
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/profile">
                        <i className="fa fa-user"></i>
                        <span>My Profile</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/user-settings">
                        <i className="fa fa-cogs"></i>
                        <span>Settings</span>
                      </Link>
                    </li>

                    <li>
                      <a href="#">
                        <i className="fa fa-comments"></i>
                        <span>Tickets</span>&nbsp;
                        <span className="badge">0</span>
                      </a>
                    </li>

                    <li>
                      <Link to="/user-orders">
                        <i className="fa fa-plus-circle"></i>
                        <span>My Orders</span>
                      </Link>
                    </li>

                    <li>
                      <a href="referrals.html">
                        <i className="fa fa-plus-circle"></i>
                        <span>My Referrals</span>
                      </a>
                    </li>
                    <li>
                      <a href="notification.html">
                        <i className="fa fa-plus-circle"></i>
                        <span>Notification</span>
                      </a>
                    </li>
                    <li>
                      <a href="activity-log.html">
                        <i className="fa fa-plus-circle"></i>
                        <span>Activity Log</span>
                      </a>
                    </li>
                    <li>
                      <Link to="logout">
                        <i className="fa fa-sign-out"></i>
                        <span>Exit</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                  >
                    <img
                      style={{ display: "inline-block" }}
                      src={require("assets/images/en.png")}
                    />
                  </a>
                  <ul className="dropdown-menu" id="languages">
                    <li>
                      <a href="?lang=en">
                        <img src={require("assets/images/en.png")} />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <br />
      </>
    );
  }
}

MainNavbar.propTypes = {
  /**
   * The layout type where the DefaultNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}

const actionCreators = {};
export default connect(mapState, actionCreators)(MainNavbar);
