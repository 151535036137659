import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "../_reducers";
import jwt from "jwt-decode"; // import dependency
import { userConstants } from "../_constants";

const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
  const token =
    JSON.parse(localStorage.getItem("user")) &&
    JSON.parse(localStorage.getItem("user"))["token"];

  if (token) {
    if (jwt(token).exp < Date.now() / 1000) {
      next(action);
      //userActions.logout();
      localStorage.removeItem("user");
      localStorage.removeItem("profile");
      store.dispatch({ type: userConstants.LOGOUT });
      //localStorage.clear();
    }
  }
  next(action);
};

const middlewares = [thunkMiddleware, checkTokenExpirationMiddleware];
if (process.env.REACT_APP_MODE === `development`) {
  const loggerMiddleware = createLogger();
  middlewares.push(loggerMiddleware);
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));
