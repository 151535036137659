import React from "react";
import { connect } from "react-redux";
import { Button, FormGroup, Form, Input, InputGroup } from "reactstrap";
import { ButtonLoading } from "components/Loading";
import DismissibleAlert from "components/common/DismissibleAlert";
import { userActions } from "../_actions";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: "",
      },
      first_name: "",
      last_name: "",
      phone: "",
      country: "",
      city: "",
      open: false,
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { getProfile } = this.props;
    getProfile();
  }

  // static getDerivedStateFromProps(props, state) {
  //   console.log("my props", props);
  //   const { profile } = props;
  //   if (profile) {
  //     let update = {
  //       first_name: profile.first_name || "",
  //       last_name: profile.last_name || "",
  //       phone: profile.phone || "",
  //       country: profile.country || "",
  //       city: profile.city || "",
  //     };
  //     return update;
  //   }
  // }

  handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    let user = JSON.parse(localStorage.getItem("user"));
    let id;
    if (user && user.user_id) {
      id = user.user_id;
    }
    console.log("logged in user", user);

    e.preventDefault();
    this.setState({ submitted: true });
    const { first_name, last_name, phone, country, city } = this.state;
    if (id) {
      var postData = {
        id: id,
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        country: country,
        city: city,
      };
      this.props.editProfile(postData);
    }
  }

  render() {
    const { user, loggingIn, alert, profile_loading, loggedIn, profile } =
      this.props;
    const { first_name, last_name, phone, country, city, submitted } =
      this.state;
    console.log("==change_name", this.state.first_name);
    return (
      <>
        <div className="container">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h2 className="panel-title">Profile / Update Profile</h2>
            </div>
            <div className="panel-body panel-currencies">
              <div className="row">
                <div className="col-xs-8" id="guide">
                  <h3>
                    <i className="fa fa-user"></i>
                    <span>{user.email}</span>
                  </h3>
                  <p className="text-ignore">
                    Welcome {user.first_name} {user.last_name}
                  </p>
                  <ul className="list-group">
                    <span className="label label-warning">
                      {user.first_name} {user.last_name}
                    </span>
                  </ul>
                  <p className="text-warning">Here you can update profile .</p>
                </div>

                <div className="col-xs-16" id="content">
                  <div className="flash-message">
                    {alert.message && (
                      <DismissibleAlert
                        message={alert.message}
                        theme={alert.type}
                      />
                    )}
                  </div>

                  <Form
                    className="login-form mx-4"
                    role="form"
                    name="form"
                    onSubmit={this.handleSubmit}
                  >
                    <FormGroup
                      className={
                        "mb-3 " +
                        (submitted && !first_name ? " has-danger" : "")
                      }
                    >
                      <InputGroup className="input-group-alternative mb-3 ">
                        <Input
                          bsSize="lg"
                          className={
                            submitted && !first_name ? "is-invalid" : ""
                          }
                          placeholder="First name"
                          type="text"
                          name="first_name"
                          // value={}
                          defaultValue={profile.first_name || ""}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      {submitted && !first_name && (
                        <div className="invalid-feedback d-block">
                          first name is required
                        </div>
                      )}
                      <InputGroup className="input-group-alternative mb-3">
                        <Input
                          bsSize="lg"
                          className={
                            submitted && !last_name ? "is-invalid" : ""
                          }
                          placeholder="Last name"
                          type="text"
                          name="last_name"
                          // value={last_name}
                          defaultValue={profile.last_name || ""}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      {submitted && !last_name && (
                        <div className="invalid-feedback d-block">
                          last name is required
                        </div>
                      )}

                      <InputGroup className="input-group-alternative mb-3">
                        <Input
                          bsSize="lg"
                          className={submitted && !phone ? "is-invalid" : ""}
                          placeholder="phone"
                          type="text"
                          name="phone"
                          // value={phone}
                          defaultValue={profile.phone || ""}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      {submitted && !phone && (
                        <div className="invalid-feedback d-block">
                          phone is required
                        </div>
                      )}

                      <InputGroup className="input-group-alternative mb-3">
                        <Input
                          bsSize="lg"
                          className={submitted && !country ? "is-invalid" : ""}
                          placeholder="country"
                          type="text"
                          name="country"
                          // value={country}
                          defaultValue={profile.country || ""}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      {submitted && !country && (
                        <div className="invalid-feedback d-block">
                          country is required
                        </div>
                      )}

                      <InputGroup className="input-group-alternative mb-3">
                        <Input
                          bsSize="lg"
                          className={submitted && !city ? "is-invalid" : ""}
                          placeholder="city"
                          type="text"
                          name="city"
                          // value={city}
                          defaultValue={profile.city || ""}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      {submitted && !city && (
                        <div className="invalid-feedback d-block">
                          city is required
                        </div>
                      )}
                    </FormGroup>

                    <div className="form-group">
                      {loggedIn ? (
                        <Button
                          className="btn btn-primary rounded submit p-3 px-5"
                          color="primary"
                          type="submit"
                          size="lg"
                          disabled={profile_loading}
                        >
                          {profile_loading ? (
                            <ButtonLoading showText={true} />
                          ) : (
                            `Update Profile`
                          )}
                        </Button>
                      ) : (
                        <Button
                          className="btn btn-primary rounded submit p-3 px-5"
                          color="primary"
                          type="submit"
                          size="lg"
                          disabled={loggingIn}
                        >
                          {loggingIn ? (
                            <ButtonLoading showText={true} />
                          ) : (
                            `Update Profile`
                          )}
                        </Button>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  const { loggedIn } = state.authentication;
  // const { authentication } = state;
  // const { user } = authentication;
  const { authentication, registration } = state;
  var user = registration.user ? registration.user : authentication.user;
  const { item: profile } = state.profile;
  return {
    loggedIn,
    alert,
    profile,
    user,
  };
}

const actionCreators = {
  getProfile: userActions.getProfile,
  editProfile: userActions.editProfile,
};
export default connect(mapState, actionCreators)(Profile);
