import React from "react";
import { connect } from "react-redux";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { commonActions } from "../_actions";
import { Link } from "react-router-dom";
import AOS from "aos";
import { registration } from "_reducers/registration.reducer";

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: "",
      },
      open: false,
      submitted: false,
    };
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // or simply just AOS.init();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
    const { web_config } = this.props;
    if (Object.entries(web_config).length === 0) {
      this.props.getconfig();
    }
  }

  render() {
    const { user } = this.props;

    return (
      <>
        <div className="container">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h2 className="panel-title">Settings</h2>
            </div>
            <div className="panel-body panel-currencies">
              <div className="row">
                <div className="col-xs-8" id="guide">
                  <h3>
                    <i className="fa fa-user"></i>
                    <span>{user && user.email}</span>
                  </h3>
                  <p className="text-ignore">Welcome to DX-exchange</p>
                  <ul className="list-group">
                    <span className="label label-warning">
                      {user && user.first_name} {user && user.last_name}
                    </span>
                  </ul>
                  <p className="text-warning">
                    In order to protect your bitcoins and personal information,
                    we strongly suggest that you use Two-Factor Authentication.
                  </p>
                </div>

                <div className="col-xs-16" id="content">
                  <div className="flash-message"></div>
                  <table className="table" id="setting_list">
                    <tbody>
                      <tr>
                        <td className="col-xs-2 text-center vertical-center">
                          <i className="fa fa-envelope-o fa-2x"></i>
                        </td>
                        <td className="col-xs-15">
                          <h4>E-mail verification</h4>
                          <p className="text-ignore">
                            Your email adress has been verified successfully,
                            remember and protect this e-mail address, it is the
                            single certificate for your account
                          </p>
                        </td>
                        <td className="col-xs-7 vertical-center">
                          <a
                            className="btn btn-success btn-block disabled"
                            href="#"
                            role="button"
                          >
                            <i className="fa fa-check-circle"></i>
                            <span>Verified</span>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-xs-2 text-center vertical-center">
                          <i className="fa fa-thumbs-o-up fa-2x"></i>
                        </td>
                        <td className="col-xs-15">
                          <h4>
                            <span>Verify Account</span>
                            <i className="fa fa-bolt text-warning"></i>
                          </h4>
                          <p className="text-ignore">
                            Help keep the bad guys out of your account by using
                            both your password and your phone
                          </p>
                        </td>
                        <td className="col-xs-7 vertical-center">
                          <a
                            className="btn btn-success btn-block disabled"
                            href="#"
                            role="button"
                          >
                            <i className="fa fa-check-circle"></i>
                            <span>Verified</span>
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td className="col-xs-2 text-center vertical-center">
                          <i className="fa fa-mobile fa-4x"></i>
                        </td>
                        <td className="col-xs-15">
                          <h4>
                            <span>Phone Number</span>
                            <i className="fa fa-bolt text-warning"></i>
                          </h4>
                          <p className="text-ignore">
                            Providing a phone number will make your account more
                            secure. You can quickly reset your password and your
                            account operations will be notified.
                          </p>
                        </td>
                        <td className="col-xs-7 vertical-center">
                          <a
                            className="btn btn-success btn-block disabled"
                            href="#"
                            role="button"
                          >
                            <i className="fa fa-check-circle"></i>
                            <span>Verified</span>
                          </a>
                          <div className="hint">
                            Verified number 098*** *0470
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="col-xs-2 text-center vertical-center">
                          <i className="fa fa-money fa-2x"></i>
                        </td>
                        <td className="col-xs-15">
                          <h4>
                            <span>Currency Preference</span>
                            <i className="fa fa-bolt text-warning"></i>
                          </h4>
                          <p className="text-ignore">
                            Please set your currency preference here to make the
                            trade faster .
                          </p>
                        </td>
                        <td className="col-xs-7 vertical-center">
                          <Link
                            className="btn btn-default btn-block"
                            to="/currency-preferences"
                          >
                            Update currency{" "}
                          </Link>
                        </td>
                      </tr>

                      <tr>
                        <td className="col-xs-2 text-center vertical-center">
                          <i className="fa fa-superscript fa-2x"></i>
                        </td>
                        <td className="col-xs-15">
                          <h4>
                            <span>Two-Factor Authentication</span>
                            <i className="fa fa-bolt text-warning"></i>
                          </h4>
                          <p className="text-ignore">
                            Two-Factor Authentication provides another layer of
                            security to your account.
                          </p>
                        </td>
                        <td className="col-xs-7 vertical-center">
                          <a
                            className="btn btn-default btn-block"
                            href="/verify/google_auth/edit"
                          >
                            Disable
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-xs-2 text-center vertical-center">
                          <i className="fa fa-key fa-2x"></i>
                        </td>
                        <td className="col-xs-15">
                          <h4>Password</h4>
                          <p className="text-ignore">
                            This password is required for login, please remember
                            it.
                          </p>
                        </td>
                        <td className="col-xs-7 vertical-center">
                          <Link
                            className="btn btn-default btn-block"
                            to="/change-password"
                          >
                            Change Password
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-xs-2 text-center vertical-center">
                          <i className="fa fa-code fa-2x"></i>
                        </td>
                        <td className="col-xs-15">
                          <h4>API Tokens</h4>
                          <p className="text-ignore">
                            Create and manage your API tokens.
                          </p>
                        </td>
                        <td className="col-xs-7 vertical-center">
                          <a
                            className="btn btn-default btn-block"
                            href="/api_tokens"
                          >
                            Set
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-xs-2 text-center vertical-center">
                          <i className="fa fa-plus-circle fa-2x"></i>
                        </td>
                        <td className="col-xs-15">
                          <h4>Referrals</h4>
                          <p className="text-ignore">
                            Use this link to refer other users
                          </p>
                          <p>
                            <a href="https://www.dxexchange.cloud/?ref=8207bcd5788f">
                              https://www.dxexchange.cloud/?ref=8207bcd5788f
                            </a>
                          </p>
                        </td>
                        <td className="col-xs-7 vertical-center"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;

  const { detail: web_config } = state.config;
  console.log(user, "===== user");
  return { user, web_config };
}

const actionCreators = {
  getconfig: commonActions.getConfig,
};

export default connect(mapState, actionCreators)(UserSettings);
