import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "js-cookie";

// reactstrap components
import { Button, FormGroup, Form, Input, InputGroup, Label } from "reactstrap";

// core components
import { ButtonLoading } from "../../components/Loading";
import DismissibleAlert from "components/common/DismissibleAlert";

import { userActions } from "../../_actions";

class Login extends React.Component {
  constructor(props) {
    super(props);

    const { from } = this.props.location.state || {
      from: { pathname: "/dashboard" },
    };

    this.state = {
      username: "",
      password: "",
      from: from,
      submitted: false,
      check: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  componentDidMount() {
    var user = Cookies.get("name");
    var pass = Cookies.get("password");
    var check = Cookies.get("check");
    this.setState({ username: user, password: pass, check: check });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password, from } = this.state;

    if (username && password) {
      this.props.login(username, password, from);
    }
  }
  setCookies = (typeCheck) => {
    const { username, password, check } = this.state;
    if (typeCheck && username.length > 0 && password.length > 0) {
      Cookies.set("name", `${username}`);
      Cookies.set("password", `${password}`);
      Cookies.set("check", `${typeCheck}`);
    }
    this.setState({
      check: !check,
    });
    //  else {
    //   Cookies.remove("name");
    //   Cookies.remove("password");
    // }
  };
  render() {
    const { loggingIn, alert, profile_loading, loggedIn } = this.props;
    const { username, password, submitted } = this.state;
    return (
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-12"></div>
          </div>

          <div className="row justify-content-center">
            {/* <div className="col-md-6 text-center mb-5">
              <img src={require("assets/images/logo/logo.png")} alt="Logo" />
            </div> */}
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5">
              <div className="login-wrap p-4 p-md-5">
                {/* <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-user-o"></span>
                </div> */}
                <h3 className="text-center mb-4">LOGIN</h3>

                <Form
                  className="login-form"
                  role="form"
                  name="form"
                  onSubmit={this.handleSubmit}
                >
                  <FormGroup
                    className={
                      "mb-3 " + (submitted && !username ? " has-danger" : "")
                    }
                  >
                    <InputGroup className="input-group-alternative">
                      <Input
                        bsSize="lg"
                        className={submitted && !username ? "is-invalid" : ""}
                        placeholder="Username"
                        type="text"
                        name="username"
                        value={username}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    {submitted && !username && (
                      <div className="invalid-feedback d-block">
                        Username is required
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup
                    className={submitted && !password ? "has-danger" : ""}
                  >
                    <InputGroup className="input-group-alternative">
                      <Input
                        bsSize="lg"
                        className={submitted && !password ? "is-invalid" : ""}
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    {submitted && !password && (
                      <div className="invalid-feedback d-block">
                        Password is required
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup check>
                    {/* <div className="custom-control custom-control-alternative custom-checkbox"> */}
                    <input
                      className=""
                      id=" customCheckLogin"
                      type="checkbox"
                      checked={this.state.check}
                      onClick={() => {
                        this.setCookies(!this.state.check);
                      }}
                    />
                    <label className="ml-3">
                      <span>Remember me</span>
                    </label>
                    {/* </div> */}
                  </FormGroup>

                  <div className="text-center">
                    {loggedIn ? (
                      <Button
                        className="btn btn-primary rounded submit p-3 px-5"
                        color="primary"
                        type="submit"
                        size="lg"
                        disabled={profile_loading}
                      >
                        {profile_loading ? (
                          <ButtonLoading showText={true} />
                        ) : (
                          `Logged in`
                        )}
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-primary rounded submit p-3 px-5"
                        color="primary"
                        type="submit"
                        size="lg"
                        disabled={loggingIn}
                      >
                        {loggingIn ? (
                          <ButtonLoading showText={true} />
                        ) : (
                          `Sign in`
                        )}
                      </Button>
                    )}
                  </div>

                  <div className="w-100 text-md-center">
                    <Link to="forgot-password">Forgot Password?</Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  const { loggingIn, loggedIn } = state.authentication;
  const profile_loading = state.profile.loading;
  return { loggingIn, loggedIn, alert, profile_loading };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

export default connect(mapState, actionCreators)(Login);
