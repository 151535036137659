import { DefaultLayout,InnerLayout } from "./layouts";
import Index from "./views/Index";
import Exchange from "./views/Exchange";
import UserSettings from "./views/UserSettings";
import Profile from "./views/Profile";
import CurrencyPreference from "./views/CurrencyPreference";

import UserOrders from "./views/UserOrders";

import Kyc from "./views/Kyc";

import ChangePassword from "./views/ChangePassword";
import Login from "./views/noauth/Login";
import Register from "./views/noauth/Register";
import ForgotPassword from "./views/noauth/ForgotPassword";
import ResetPassword from "./views/noauth/ResetPassword";
import Logout from "./views/noauth/Logout";

export const routes = [
  {
    path: "/exchange/:code",
    exact: true,
    layout: DefaultLayout,
    component: Exchange,
    headerFixed : true
  },
  {
    path: "/exchange",
    exact: true,
    layout: DefaultLayout,
    component: Exchange,
    headerFixed : true
  },
    {
    path: "/user-settings",
    exact: true,
    layout: InnerLayout,
    component: UserSettings
  },
  {
    path: "/profile",
    exact: true,
    layout: InnerLayout,
    component: Profile
  },
  {
    path: "/currency-preferences",
    exact: true,
    layout: InnerLayout,
    component: CurrencyPreference
  },

  {
    path: "/user-orders",
    exact: true,
    layout: InnerLayout,
    component: UserOrders
  },
  {
    path: "/user-kyc",
    exact: true,
    layout: InnerLayout,
    component: Kyc
  },
  
  
  {
    path: "/change-password",
    exact: true,
    layout: InnerLayout,
    component: ChangePassword
  },
  
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: Index
  },

  {
    path: "/login",
    exact: true,
    layout: DefaultLayout,
    component: Login,
    headerFixed : true
  },
  {
    path: "/signup",
    exact: true,
    layout: DefaultLayout,
    component: Register,
    headerFixed : true
  },
  {
    path: "/forgot-password",
    exact: true,
    layout: DefaultLayout,
    component: ForgotPassword,
    headerFixed : true
  },
  {
    path: "/reset-password",
    exact: true,
    layout: DefaultLayout,
    component: ResetPassword,
    headerFixed : true
  },
  {
    path: "/logout",
    exact: true,
    component: Logout
  }
];

