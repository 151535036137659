import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";
import { toast } from "react-toastify";

export const userActions = {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
  editProfile,
  changePassword,
  updateKyc,
  buyCrypto,
  sellCrypto,
  completedOrders,
  openOrders,
  getProfile,
  openOrdersAll,
  completedOrdersAll,
  editBuyOrder,
  getCurrency,
  editCurrency,
};

function login(username, password, from) {
  return (dispatch) => {
    dispatch(request({ username }));
    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        // dispatch(alertActions.success(user.message));
        toast.success(user.message);
        if (from) {
          history.replace("/exchange");
        } else {
          history.replace("/exchange");
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
  function request_profile() {
    return { type: userConstants.PROFILE_REQUEST };
  }
  function success_profile(profile) {
    return { type: userConstants.PROFILE_SUCCESS, payload: { profile } };
  }
  function failure_profile(error) {
    return { type: userConstants.PROFILE_FAILURE, payload: { error } };
  }
}
function forgotPassword(email) {
  return (dispatch) => {
    dispatch(request());
    userService.forgotPassword(email).then(
      (response) => {
        console.log("all response", response);
        dispatch(success());
        toast.success("You are logged in successfully.");
        //dispatch(alertActions.success('You are logged in successfully.'));

        dispatch(alertActions.success(response.message));
      },
      (error) => {
        // dispatch(failure(error.toString()));
        toast.error(error.toString());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST };
  }
  function success() {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

function resetPassword(password, confirm_password, reset_token) {
  return (dispatch) => {
    dispatch(request());
    userService.resetPassword(password, confirm_password, reset_token).then(
      (response) => {
        console.log("all response", response);
        dispatch(success());
        dispatch(alertActions.success(response.message));
        history.replace("/login");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.RESET_PASSWORD_REQUEST };
  }
  function success() {
    return { type: userConstants.RESET_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.RESET_PASSWORD_FAILURE, error };
  }
}
function changePassword(password, confirm_password) {
  return (dispatch) => {
    dispatch(request());
    userService.changePassword(password, confirm_password).then(
      (response) => {
        dispatch(success());
        // dispatch(alertActions.success(response.message));

        toast.success(response.message);
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }
  function success() {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}

function buyCrypto(post_body, params) {
  return (dispatch) => {
    dispatch(request());
    userService.buyCrypto(post_body).then(
      (response) => {
        dispatch(success());
        dispatch(alertActions.success(response.message));
        toast.success(" ✓ Purchase done successfully.");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.BUY_CRYPTO_REQUEST };
  }
  function success() {
    return { type: userConstants.BUY_CRYPTO_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.BUY_CRYPTO_FAILURE, error };
  }
}

function sellCrypto(post_body, params) {
  return (dispatch) => {
    dispatch(request());
    userService.sellCrypto(post_body).then(
      (response) => {
        dispatch(success());
        dispatch(alertActions.success(response.message));
        toast.success(" ✓ Sell done successfully.");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.SELL_CRYPTO_REQUEST };
  }
  function success() {
    return { type: userConstants.SELL_CRYPTO_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.SELL_CRYPTO_FAILURE, error };
  }
}

function completedOrders(post_body) {
  return (dispatch) => {
    dispatch(request());
    userService.completedOrders(post_body).then(
      (response) => {
        dispatch(success(response && response.data));
        dispatch(alertActions.success(response && response.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.COMPLETED_ORDERS_REQUEST };
  }
  function success(items) {
    return { type: userConstants.COMPLETED_ORDERS_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.COMPLETED_ORDERS_FAILURE, error };
  }
}

function openOrders(post_body) {
  return (dispatch) => {
    dispatch(request());
    userService.openOrders(post_body).then(
      (response) => {
        dispatch(success(response && response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.OPEN_ORDERS_REQUEST };
  }
  function success(items) {
    return { type: userConstants.OPEN_ORDERS_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.OPEN_ORDERS_FAILURE, error };
  }
}

function getProfile() {
  return (dispatch) => {
    dispatch(request());
    userService.getProfile().then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.PROFILE_REQUEST };
  }
  function success(item) {
    return { type: userConstants.PROFILE_SUCCESS, item };
  }
  function failure(error) {
    return { type: userConstants.PROFILE_FAILURE, error };
  }
}

function updateKyc(post_body) {
  let formData = new FormData();
  formData.append("first_name", post_body.first_name);
  formData.append("middle_name", post_body.middle_name);

  formData.append("last_name", post_body.last_name);
  formData.append("dob", post_body.dob);
  formData.append("address", post_body.address);
  formData.append("pan_card_no", post_body.pan_card_no);
  formData.append("pan_card_no_confirm", post_body.pan_card_no_confirm);
  formData.append("aadhar_no", post_body.aadhar_no);
  formData.append("aadhar_no_confirm", post_body.aadhar_no_confirm);
  formData.append("doc_type", post_body.doc_type);
  formData.append("kyc_type", post_body.kyc_type);
  formData.append("phone", post_body.phone);
  formData.append("state", post_body.state);
  formData.append("zip", post_body.zip);
  formData.append("city", post_body.city);

  formData.append("country", post_body.country);
  formData.append("pan_card_image", post_body.pan_card_image);
  formData.append("aadhar_image_front", post_body.aadhar_image_front);
  formData.append("aadhar_image_back", post_body.aadhar_image_back);
  formData.append("profile_image", post_body.profile_image);

  return (dispatch) => {
    dispatch(request());
    userService.updateKyc(formData).then(
      (response) => {
        dispatch(success());
        dispatch(alertActions.success(response.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.UPDATE_KYC_REQUEST };
  }
  function success() {
    return { type: userConstants.UPDATE_KYC_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_KYC_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  window.location.pathname = "/";
  //window.location.reload();
  //return { type: userConstants.LOGOUT };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));
    userService.register(user).then(
      (user) => {
        dispatch(success_user(user));
        history.replace("/exchange");
        dispatch(alertActions.success(user.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function success_user(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
  function request_profile() {
    return { type: userConstants.PROFILE_REQUEST };
  }
  function success_profile(profile) {
    return { type: userConstants.PROFILE_SUCCESS, payload: { profile } };
  }
  function failure_profile(error) {
    return { type: userConstants.PROFILE_FAILURE, payload: { error } };
  }
}

function editProfile(data) {
  return (dispatch) => {
    dispatch(request());
    userService.update(data).then(
      (response) => {
        dispatch(success(response.data));
        toast.success(" ✓ Profile Information update successfully.");
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.PROFILE_UPDATE_REQUEST };
  }
  function success(profile) {
    return { type: userConstants.PROFILE_UPDATE_SUCCESS, payload: { profile } };
  }
  function failure(error) {
    return { type: userConstants.PROFILE_UPDATE_FAILURE, error };
  }
}

function completedOrdersAll() {
  return (dispatch) => {
    dispatch(request());
    userService.completedOrdersAll().then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.COMPLETED_ORDERS_ALL_REQUEST };
  }
  function success(items) {
    return { type: userConstants.COMPLETED_ORDERS_ALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.COMPLETED_ORDERS_ALL_FAILURE, error };
  }
}

function openOrdersAll() {
  return (dispatch) => {
    dispatch(request());
    userService.openOrdersAll().then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.OPEN_ORDERS_ALL_REQUEST };
  }
  function success(items) {
    return { type: userConstants.OPEN_ORDERS_ALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.OPEN_ORDERS_ALL_FAILURE, error };
  }
}

function editBuyOrder(data) {
  return (dispatch) => {
    dispatch(request());
    userService.editBuyOrder(data).then(
      (response) => {
        dispatch(success(response.data));
        toast.success(" ✓ Order Information update successfully.");
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.BUY_ORDER_UPDATE_REQUEST };
  }
  function success() {
    return { type: userConstants.BUY_ORDER_UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.BUY_ORDER_UPDATE_FAILURE, error };
  }
}

function getCurrency() {
  return (dispatch) => {
    dispatch(request());
    userService.getCurrency().then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.USER_CURRENCY_REQUEST };
  }
  function success(items) {
    return { type: userConstants.USER_CURRENCY_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.USER_CURRENCY_FAILURE, error };
  }
}

function editCurrency(asset_id) {
  return (dispatch) => {
    dispatch(request());
    userService.editCurrency(asset_id).then(
      (response) => {
        dispatch(success(response.data));
        toast.success(
          " ✓ Currency Preferences Information update successfully."
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.USER_CURRENCY_UPDATE_REQUEST };
  }
  function success() {
    return { type: userConstants.USER_CURRENCY_UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.USER_CURRENCY_UPDATE_FAILURE, error };
  }
}
