import { commonConstants } from '../../_constants';

const initialState = {
  items: [],
  loading_orderbook: false,
  fetched_orderbook: false,
  error_orderbook: null
};

export default function orderbooklist(state = initialState, action) {
  switch (action.type) {
    case commonConstants.ORDERBOOK_LIST_REQUEST:
      return {
        ...state,
        loading_orderbook: true,
        error_orderbook: null
      };
    case commonConstants.ORDERBOOK_LIST_SUCCESS:
      return {
        ...state,
        loading_orderbook: false,
        fetched_orderbook: true,
        items: action.items
      };
    case commonConstants.ORDERBOOK_LIST_FAILURE:
      return {
        ...state,
        loading_orderbook: false,
        error_orderbook: action.error,
        items: []
      };
    default:
      return state
  }
}
