import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
//import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";

import "../assets/inner/assets/empty.css";
//import "../assets/inner/funds.css";
import "../assets/inner/assets/application.css";
import "../assets/inner/assets/custom.css";



const InnerLayout = ({ children, noNavbar, noFooter,sidebar }) => (
  <>
    <div id="wrap">
      {!noNavbar && <MainNavbar />}
      {children}
      <div id="push"></div>
    </div>
    {!noFooter && <MainFooter />}
  </>  
);

InnerLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

InnerLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

function mapState(state) {
  const sidebar = state.config.sidebar_size
  return {sidebar};
}

export default connect(mapState)(InnerLayout);
