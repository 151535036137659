import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { Button, FormGroup, Form, Input, InputGroup } from "reactstrap";
import { ButtonLoading } from "components/Loading";
import DismissibleAlert from "components/common/DismissibleAlert";
import { userActions } from "../_actions";

class ChangePassword extends React.Component {
  componentDidMount() {
    const { loggedIn } = this.props;
    if (!loggedIn) {
      this.props.history.push("/login");
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirm_password: "",

      submitted: false,
    };
    this.state = queryString.parse(this.props.location.search);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { password, confirm_password } = this.state;
    if (password) {
      this.props.changePassword(password, confirm_password);
    }
  }

  render() {
    const { loggingIn, alert, profile_loading, loggedIn } = this.props;
    const { password, confirm_password, submitted } = this.state;
    return (
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5">
              <div className="login-wrap p-4 p-md-5">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-key"></span>
                </div>
                <h3 className="text-center mb-4">CHANGE PASSWORD</h3>

                {alert.message && (
                  <DismissibleAlert
                    message={alert.message}
                    theme={alert.type}
                  />
                )}

                <Form
                  className="login-form"
                  role="form"
                  name="form"
                  onSubmit={this.handleSubmit}
                >
                  <FormGroup
                    className={
                      "mb-3 " + (submitted && !password ? " has-danger" : "")
                    }
                  >
                    <InputGroup className="input-group-alternative">
                      <Input
                        bsSize="lg"
                        className={submitted && !password ? "is-invalid" : ""}
                        placeholder="New Password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    {submitted && !password && (
                      <div className="invalid-feedback d-block">
                        new password is required
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup
                    className={
                      "mb-3 " + (submitted && !password ? " has-danger" : "")
                    }
                  >
                    <InputGroup className="input-group-alternative">
                      <Input
                        bsSize="lg"
                        className={
                          submitted && !confirm_password ? "is-invalid" : ""
                        }
                        placeholder="Confirm Password"
                        type="password"
                        name="confirm_password"
                        value={confirm_password}
                        onChange={this.handleChange}
                      />
                    </InputGroup>
                    {submitted && !confirm_password && (
                      <div className="invalid-feedback d-block">
                        confirm password is required
                      </div>
                    )}
                  </FormGroup>

                  <div className="form-group d-flex justify-content-center">
                    {loggedIn ? (
                      <Button
                        className="btn btn-primary rounded submit p-3 px-5"
                        color="primary"
                        type="submit"
                        size="lg"
                        disabled={profile_loading}
                      >
                        {profile_loading ? (
                          <ButtonLoading showText={true} />
                        ) : (
                          `Update Password`
                        )}
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-primary rounded submit p-3 px-5"
                        color="primary"
                        type="submit"
                        size="lg"
                        disabled={loggingIn}
                      >
                        {loggingIn ? (
                          <ButtonLoading showText={true} />
                        ) : (
                          `Update Password`
                        )}
                      </Button>
                    )}
                  </div>
                  {/* <div className="w-100 text-md-center">
                    <Link to="login">Login</Link>
                  </div> */}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  const { loggedIn } = state.authentication;
  return { loggedIn, alert };
}

const actionCreators = {
  changePassword: userActions.changePassword,
};

export default connect(mapState, actionCreators)(ChangePassword);
