import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Nav, NavItem, NavLink } from "reactstrap";
// reactstrap components
import { Button, UncontrolledTooltip } from "reactstrap";

import { Link } from "react-router-dom";

const date = new Date().getFullYear();

const MainFooter = ({ contained, menuItems, copyright }) => (
  // <footer className="theme-footer">
  //   <img src={require('assets/images/shape/31.png')} alt="" className="shape"/>
  //     <div className="container">
  //       <div className="inner-wrapper">
  //         <div className="top-footer-data-wrapper">
  //           <div className="row">
  //             <div className="col-lg-4 col-sm-6 footer-logo">
  //               <div className="logo"><a href="index.html"><img src={require('assets/images/logo/logo.png')} alt="Logo"/></a></div>
  //               <a href="#" className="email">crytoco@company.com</a>
  //               <a href="#" className="mobile">648-xxx-xxxx</a>
  //             </div>
  //             <div className="col-lg-2 col-sm-6 footer-list">
  //               <h4 className="title">Quick Links</h4>
  //               <ul>
  //                 <li><a href="#">How it Works</a></li>
  //                 <li><a href="#">Guarantee</a></li>
  //                 <li><a href="#">Security</a></li>
  //                 <li><a href="#">Report Bug</a></li>
  //                 <li><a href="#">Pricing</a></li>
  //               </ul>
  //             </div>
  //             <div className="col-lg-3 col-sm-6 footer-list">
  //               <h4 className="title">About Us</h4>
  //               <ul>
  //                 <li><a href="#">Token</a></li>
  //                 <li><a href="#">API</a></li>
  //                 <li><a href="#">Referral Program</a></li>
  //                 <li><a href="#">Blog</a></li>
  //                 <li><a href="#">Careers</a></li>
  //               </ul>
  //             </div>
  //             <div className="col-lg-3 col-sm-6 footer-list">
  //               <h4 className="title">Become A Member</h4>
  //               <ul>
  //                 <li><a href="#">Contributor</a></li>
  //                 <li><a href="#">Union Member</a></li>
  //                 <li><a href="#">Processing</a></li>
  //                 <li><a href="#">Legal Action</a></li>
  //               </ul>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="bottom-footer clearfix">
  //           <p className="copyright">&copy; 2021 <a href="#">Crypto Currency Exchange</a> All Right Reserved</p>
  //           <ul>
  //             <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
  //             <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
  //             <li><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
  //           </ul>
  //         </div>
  //       </div>
  //     </div>
  // </footer>

  <div class="footer">
    <div className="container-fluid body-px">
      <div className="row">
        <div className="col-lg-3">
          <div className="footer-logo-div">
            <a title="logo">
              <img
                className="footer-logo"
                src={require("../../assets/images/logo-big.png")}
                // src="images/logo-big.png"
                alt="waxirz2"
              />
            </a>
          </div>
        </div>
        <div className="col-lg-9">
          <ul className="list-unstyled d-flex justify-content-lg-end justify-content-center footer-store-icon">
            <li>
              <a>
                <img
                  src={require("../../assets/images/google-play-icon.svg")}
                  // src="images/google-play-icon.svg"
                />
              </a>
            </li>
            <li>
              <a>
                <img
                  src={require("../../assets/images/app-store-icon.svg")}
                  // src="images/app-store-icon.svg"
                />
              </a>
            </li>
            <li>
              <a>
                <img
                  src={require("../../assets/images/windows-icon.svg")}
                  // src="images/windows-icon.svg"
                />
              </a>
            </li>
            <li>
              <a>
                <img
                  src={require("../../assets/images/macapp-icon.svg")}
                  // src="images/macapp-icon.svg"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr className="my-4" />
      <div className="menu">
        <div className="menu-item">
          <span className="title">About</span>
          <div className="submenu">
            <ul>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  WRX Coin
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Blog
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Careers
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  WazirX Warriors
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Announcements
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Media Assets
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Terms Of Use
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="menu-item">
          <span className="title">Services</span>
          <div className="submenu">
            <ul>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Download
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Corporate Account
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Referral Program
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Market Maker Program
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  WazirX API
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  List Your Coin
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Partnerships &amp; Enquiries
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="menu-item">
          <span className="title">Support</span>
          <div className="submenu">
            <ul>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Help Center
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Fees
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Security
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  Law Enforcement Request
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer">
                  What Is Bitcoin
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="menu-item">
          <span className="title">Socials</span>
          <div className="submenu">
            <ul>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <div className="box">
                    <i className="bi bi-twitter"></i>
                  </div>
                  <span className="text">Twitter</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <div className="box">
                    <i className="bi bi-facebook"></i>
                  </div>
                  <span className="text">Facebook</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <div className="box">
                    <i className="bi bi-instagram"></i>
                  </div>
                  <span className="text">Instagram</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <div className="box">
                    <i className="bi bi-linkedin"></i>
                  </div>
                  <span className="text">LinkedIn</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <div className="box">
                    <i className="bi bi-telegram"></i>
                  </div>
                  <span className="text">Telegram</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <div className="box">
                    <i className="bi bi-youtube"></i>
                  </div>
                  <span className="text">YouTube</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <div className="box">
                    <i className="bi bi-reddit"></i>
                  </div>
                  <span className="text">Reddit</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <div className="box">
                    <img
                      src={require("../../assets/images/coinmarket.png")}
                      // src="images/coinmarket.png"
                    />
                  </div>
                  <span className="text">CoinMarketCap</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="menu-item">
          <span className="title">Buy Crypto</span>
          <div className="submenu">
            <ul>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <span className="text">Buy Bitcoin</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <span className="text">Buy Ethereum</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <span className="text">Buy USDT</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <span className="text">Buy WRX Coin</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <span className="text">Buy Tron</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <span className="text">Buy Matic</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <span className="text">Buy Dogecoin</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <span className="text">Buy SHIB</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <span className="text">Buy Solana</span>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="social-icon"
                  rel="noopener noreferrer"
                >
                  <span className="text">Buy Cardano</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr className="my-4" />
      <div className="bottom-footer">© Wazirx2. All rights reserved</div>
    </div>
  </div>
);

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string,
};

MainFooter.defaultProps = {
  contained: false,
  copyright: "Copyright © " + date + " DXExchange",
  menuItems: [
    {
      title: "Privacy & Cookie Policy",
      to: "/page/privacy-policy",
    },
    {
      title: "Terms of Service",
      to: "/page/terms-conditions",
    },
  ],
};

export default MainFooter;
