import { userConstants } from '../_constants';

var storageData = localStorage.getItem('profile');
let item = [];
if(storageData !== null && storageData !== ''){
  item = JSON.parse(localStorage.getItem('profile'));
  delete item.company;
}

const initialState = {
  item: item ? item : [],
  loading: false,
  loading_bkc: false,
  edit_mode:{
    name : false
  },
  error: null
};

export function profile(state = initialState, action) {
  switch (action.type) {
    case userConstants.PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case userConstants.PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.item
      };
    case userConstants.PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        item : null
      };
    case userConstants.PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case userConstants.PROFILE_UPDATE_SUCCESS:
      let data1 = action.payload.profile;
      delete data1.company;
      return {
        ...state,
        loading: false,
        items: data1,
        edit_mode:{
          ...state.edit_mode,
          name : false
        }
      };
    case userConstants.PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.BRAND_COLOR_UPDATE_REQUEST:
      return {
        ...state,
        loading_bkc: true,
        error: null
      };
    case userConstants.BRAND_COLOR_UPDATE_SUCCESS:
      let data2 = action.payload.profile;
      delete data2.company;
      return {
        ...state,
        loading_bkc: false,
        items: data2
      };
    case userConstants.BRAND_COLOR_UPDATE_FAILURE:
      return {
        ...state,
        loading_bkc: false,
        error: action.error
      };
    case userConstants.PROFILE_EDIT_CHANGE_MODE:
      return {
        ...state,
        edit_mode:{...state.edit_mode,...action.data}
      };
    default:
      return state
  }
}
