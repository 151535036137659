import React from "react";
import { Alert } from "reactstrap";

export default class DismissibleAlert extends React.Component {
  constructor(props) {
    super(props);
    this.dismiss = this.dismiss.bind(this);
    this.state = { visible: true };
  }

  render() {
    return (
      <Alert
        className={this.props.className}
        dismissible={this.dismiss}
        open={this.state.visible}
        color={this.props.theme}
      >
        {this.props.message}
      </Alert>
    );
  }

  dismiss() {
    this.setState({ visible: false });
  }
}
