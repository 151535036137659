import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { routes } from "../routes";
import { history } from "../_helpers";
import { alertActions, commonActions } from "../_actions";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "react-router-scroll-top";
import "react-toastify/dist/ReactToastify.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/nucleo/css/menucss.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";

class App extends React.Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
      if (action === "PUSH") {
        this.props.toggleSidebar(false);
      }
    });
  }

  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <ScrollToTop>
          <ToastContainer autoClose={3000} />
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={(props) => {
                    return route.layout ? (
                      <route.layout headerFixed={route.headerFixed} {...props}>
                        <route.component {...props} />
                      </route.layout>
                    ) : (
                      <route.component {...props} />
                    );
                  }}
                />
              );
            })}
            <Redirect to="/" />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

function mapState(state) {
  const sidebar = state.config.sidebar;
  return { sidebar };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  toggleSidebar: commonActions.toggleSidebar,
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
