import { userConstants } from '../../_constants';

const initialState = {
  items: [],
  loading_c: false,
  fetched_c: false,
  error_c: null
};

export default function completed_orders_list(state = initialState, action) {
  switch (action.type) {
    case userConstants.COMPLETED_ORDERS_REQUEST:
      return {
        ...state,
        loading_c: true,
        error_c: null
      };
    case userConstants.COMPLETED_ORDERS_SUCCESS:
      return {
        ...state,
        loading_c: false,
        fetched_c: true,
        items: action.items
      };
    case userConstants.COMPLETED_ORDERS_FAILURE:
      return {
        ...state,
        loading_c: false,
        error_c: action.error,
        items: []
      };
    default:
      return state
  }
}
